import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/user";

export default function ChangeLanguage() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState<Language>(i18n.language as Language);
    const handleUpdate = () => {
        i18n.changeLanguage(language);
        window.location.reload();
    };
    return (
        <div className="ms-auto max-w-[917px] px-4 ">
            <h2 className="mb-10 text-center text-[28px] font-medium text-[rgba(23,23,23,1)]">{t("language")}</h2>
            <div>
                <span className="mb-4 block text-xl font-medium text-[rgba(38,38,38,1)]">{t("account.change-language")}</span>
                <div className="flex gap-8 max-xl:flex-col">
                    <button
                        onClick={() => setLanguage("en")}
                        className="flex flex-1 items-center justify-between rounded-lg border border-[rgba(212,212,212,1)] px-4 py-[18px] text-[rgba(22,22,22,1)]"
                    >
                        <span>English</span>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11" cy="11" r="6.5625" stroke="#0B4C24" strokeWidth="0.875" />
                            {language === "en" && <circle cx="11" cy="11" r="5.25" fill="#0A4C26" />}
                        </svg>
                    </button>
                    <button
                        onClick={() => setLanguage("ar")}
                        className="flex flex-1 items-center justify-between rounded-lg border border-[rgba(212,212,212,1)] px-4 py-[18px] text-[rgba(22,22,22,1)]"
                    >
                        <span>العربية</span>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11" cy="11" r="6.5625" stroke="#0B4C24" strokeWidth="0.875" />
                            {language === "ar" && <circle cx="11" cy="11" r="5.25" fill="#0A4C26" />}
                        </svg>
                    </button>
                </div>
                <div className="mx-auto mt-20 flex w-full max-w-[368px] flex-col gap-4">
                    <button
                        className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                        onClick={handleUpdate}
                    >
                        {t("ok")}
                    </button>
                </div>
            </div>
        </div>
    );
}
