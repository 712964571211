import axios from "axios";
import Product from "../types/product";

export async function getProduct({ productId }: { productId: string }) {
    try {
        const response = await axios.get(`/product/${productId}`);
        const data = response.data;
        return data.data as Product;
    } catch (error) {
        console.error(error);
    }
}
