import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Shop } from "../../types/shop";

export default function ShopCard({ shop }: { shop: Shop }) {
    const { t } = useTranslation();
    return (
        <Link
            to={`/shop/${shop.id}`}
            className="ws-[228psx] flex h-full flex-col rounded-xl border border-[rgba(229,229,229,1)] bg-white p-2"
        >
            <div className="mb-3.5 flex h-[170px] items-center justify-center overflow-hidden rounded-lg bg-[rgba(250,250,250,1)] p-8">
                <img
                    src={shop.image}
                    alt={shop.name}
                    className="w-full object-cover"
                />
            </div>
            <div className="mb-1.5 flex items-center justify-between">
                {shop.department && (
                    <div className="text-sm font-light text-[rgba(115,115,115,1)]">
                        {shop.department.name}
                    </div>
                )}
                <div className="flex items-center gap-x-0.5">
                    <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.3348 8.47079L12.2298 7.84374L10.3899 3.92457C10.2524 3.63191 9.77227 3.63191 9.63484 3.92457L7.79544 7.84374L3.69044 8.47079C3.35327 8.52253 3.21862 8.93258 3.45509 9.17461L6.4373 12.2314L5.73237 16.5528C5.67617 16.8961 6.04283 17.1537 6.34606 16.9851L10.0126 14.9588L13.6792 16.9857C13.9796 17.1526 14.3496 16.9 14.2929 16.5534L13.588 12.2319L16.5702 9.17517C16.8066 8.93258 16.6714 8.52253 16.3348 8.47079Z"
                            fill="#FFC107"
                        />
                    </svg>
                    <span>{t("rate")}</span>
                    <span>{shop.rating}</span>
                </div>
            </div>
            <div className="font-medium text-[rgba(23,23,23,1)]">
                {shop.name}
            </div>
        </Link>
    );
}
