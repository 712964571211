import { useTranslation } from "react-i18next";

const Notes = ({
    notes,
    setNotes,
}: {
    notes: string;
    setNotes: (value: string) => void;
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-medium text-[rgba(38,38,38,1)]">
                {t("notes")}
            </span>
            <div className="flex flex-col gap-2 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4">
                <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="min-h-[84px] w-full text-[rgba(38,38,38,1)] placeholder:text-[rgba(229,229,229,1)] focus:outline-none"
                    placeholder={t("enter")}
                ></textarea>
            </div>
        </div>
    );
};

export default Notes;
