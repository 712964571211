import { useState, useRef, useEffect } from "react";
import Logo from "../assets/logo";
import { cn } from "../lib/utils";
import { useTranslation } from "react-i18next";
import useModalsStore from "../store/modalsStore";
import useUserStore from "../store/userStore";
import useSlaughterStore from "../store/slaughterStore";
import useCartStore, { CartType } from "../store/cartStore";
import useDonationsStore from "../store/donations";
import {
    invalidate,
    setCountry,
    setLanguage,
    getCurrency,
} from "../lib/middleware";

// export default function Wallet() {
//     const {
//         isLanguageCountryModalOpen,
//         setLanguageCountryModalOpen,
//         setLoginModalOpen,
//     } = useModalsStore();
//     const [step, setStep] = useState(1);
//     const { i18n, t } = useTranslation();
//     const [selectedLanguage, setSelectedLanguage] = useState("");
//     const [selectedCountry, setSelectedCountry] = useState("");

//     const handleContinue = () => {
//         switch (step) {
//             case 1:
//                 i18n.changeLanguage(selectedLanguage);
//                 setLanguage(selectedLanguage);
//                 setStep(2);
//                 break;
//             case 2:
//                 setLanguageCountryModalOpen(false);
//                 setCountry(selectedLanguage);
//                 if (localStorage.getItem("firstVisit")) {
//                     setLoginModalOpen(true);
//                 }
//                 invalidate();
//                 setStep(1);
//                 break;
//         }
//     };
//     return (
//         <div
//             className={cn("flex flex-col", {
//                 //hidden: !isLanguageCountryModalOpen,
//             })}
//         >
//             <div className="mb-4 flex">
//                 <button
//                     className={cn("transition hover:scale-125 rtl:rotate-180", {
//                         hidden: step === 1,
//                     })}
//                     onClick={() => setStep(1)}
//                 >
//                     <svg
//                         width="40"
//                         height="41"
//                         viewBox="0 0 40 41"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <g filter="url(#filter0_d_258_156948)">
//                             <path
//                                 d="M22.5 27.7148L15 19.5898L22.5 11.4648"
//                                 stroke="#262626"
//                                 strokeWidth="3"
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                             />
//                         </g>
//                         <defs>
//                             <filter
//                                 id="filter0_d_258_156948"
//                                 x="-5"
//                                 y="0.214844"
//                                 width="50"
//                                 height="50"
//                                 filterUnits="userSpaceOnUse"
//                                 colorInterpolationFilters="sRGB"
//                             >
//                                 <feFlood
//                                     floodOpacity="0"
//                                     result="BackgroundImageFix"
//                                 />
//                                 <feColorMatrix
//                                     in="SourceAlpha"
//                                     type="matrix"
//                                     values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//                                     result="hardAlpha"
//                                 />
//                                 <feOffset dy="5" />
//                                 <feGaussianBlur stdDeviation="2.5" />
//                                 <feComposite in2="hardAlpha" operator="out" />
//                                 <feColorMatrix
//                                     type="matrix"
//                                     values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
//                                 />
//                                 <feBlend
//                                     mode="normal"
//                                     in2="BackgroundImageFix"
//                                     result="effect1_dropShadow_258_156948"
//                                 />
//                                 <feBlend
//                                     mode="normal"
//                                     in="SourceGraphic"
//                                     in2="effect1_dropShadow_258_156948"
//                                     result="shape"
//                                 />
//                             </filter>
//                         </defs>
//                     </svg>
//                 </button>
//                 <button
//                     className="ms-auto transition hover:scale-125"
//                     onClick={() => setLanguageCountryModalOpen(false)}
//                 >
//                     <svg
//                         width="32"
//                         height="33"
//                         viewBox="0 0 32 33"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <path
//                             fillRule="evenodd"
//                             clipRule="evenodd"
//                             d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
//                             fill="#262626"
//                         />
//                     </svg>
//                 </button>
//             </div>

//             <button
//                 className={cn(" bg-primary py-4 text-white    ", {
//                     hidden: step > 2,
//                 })}
//                 onClick={handleContinue}
//             >
//                 {t("modals.recharge-btn")}
//             </button>
//             <button
//                 className={cn(" bg-white py-4 text-black    ", {
//                     // hidden: step > 2,
//                 })}
//                 onClick={handleContinue}
//             >
//                 {t("modals.transfer-btn")}
//             </button>
//         </div>
//     );
// }

export default function Wallet({ type = "all" }: { type?: CartType }) {
    const cartBalance = useCartStore((state) => state.balance);
    const setCartBalance = useCartStore((state) => state.setBalance);
    const slaughterBalance = useSlaughterStore((state) => state.balance);
    const setSlaughterBalance = useSlaughterStore((state) => state.setBalance);
    const donatorBalance = useDonationsStore((state) => state.balance);
    const setDonatorBalance = useDonationsStore((state) => state.setBalance);

    const balance = cartBalance;
    const setBalance = setCartBalance;

    const { t } = useTranslation();
    const userBalance = useUserStore((state) => state.user?.balance);
    const [error, setError] = useState(false);
    const ref = useRef<HTMLInputElement>(null);
    const [usedValue, setUsedBalance] = useState(userBalance);

    // const handleUseWallet = () => {
    //     if (balance) {
    //         setBalance("");
    //         setUsedBalance("");
    //     } else setBalance(usedValue);
    //     console.log("balance", balance);
    //     console.log("usedValue", usedValue);
    // };
    // useEffect(() => {
    //     handleUseWallet();
    // }, []);

    return (
        <div className="mx-auto flex max-w-[852px] flex-col px-4 pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {t("wallet")}
            </h2>
            <div className="flex flex-col gap-6">
                <div className="contents">
                    <div className="flex flex-col rounded-xl bg-white p-4 shadow-[0px_4px_4px_0px_rgba(182,182,182,0.15)]">
                        <div className="mb-4 flex items-center gap-2">
                            <img
                                src="/Cash.png"
                                alt="Cash"
                                className="h-8 w-8"
                            />
                            <span className="text-xl font-semibold text-[rgba(38,38,38,1)]">
                                Available Balance
                            </span>
                        </div>

                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2 rounded-lg border border-[rgba(245,245,245,1)] bg-[rgba(250,250,250,1)] p-4 max-md:flex-col">
                                <div>
                                    {`${userBalance ? userBalance : "0"}` +
                                        " " +
                                        // `${getCurrency()} ? ${getCurrency()} : ""`}
                                        `${getCurrency()}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
