import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../lib/utils";
import useUserStore from "../../store/userStore";

const UsePoints = ({
    usePoints,
    setUsePoints,
}: {
    usePoints: boolean;
    setUsePoints: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const userPoints = useUserStore((state) => state.user?.points);

    return (
        <div className="flex w-full overflow-hidden rounded-lg border-y border-s border-[rgba(229,229,229,1)] bg-white">
            <div className="flex flex-1 items-center gap-2 px-5 py-[18px]">
                <div className="w-0 flex-1 bg-transparent pb-0.5 text-primary placeholder:text-primary focus:outline-none">
                    {t("account.you-have") +
                        ` ${+userPoints!} ` +
                        t("account.points") +
                        ` (${+userPoints! / 10 + " " + t("AED")})`}
                </div>
            </div>
            <button
                onClick={() => setUsePoints(!usePoints)}
                className={cn(
                    "flex w-20 items-center justify-center bg-primary px-3 text-white transition hover:opacity-80 sm:w-40",
                    {
                        "bg-[rgba(240,61,67,1)]": usePoints,
                    },
                )}
            >
                {usePoints ? t("cancel") : t("use")}
            </button>
        </div>
    );
};

export default UsePoints;
