import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { toArabic } from "../../lib/utils";
import { cn } from "../../lib/utils";
import { DriverSvg } from "../../assets/DriverSvg";

const DriverTip = ({
    driverTip,
    setDriverTip,
}: {
    driverTip: number;
    setDriverTip: Dispatch<SetStateAction<number>>;
}) => {
    const { t } = useTranslation();
    const TIPS = [5, 10, 20, 50];

    const handleSetTip = (tip: number) => {
        if (tip === driverTip) {
            setDriverTip(0);
        } else {
            setDriverTip(tip);
        }
    };

    return (
        <div className="flex flex-col gap-4 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4">
            <div className="flex flex-1 items-center gap-4">
                <DriverSvg />
                <div className="flex flex-col leading-none">
                    <span className="mb-1.5 text-lg font-medium text-[rgba(38,38,38,1)]">
                        {t("driver-tip")}
                    </span>
                    <span className="text-[rgba(64,64,64,1)]">
                        {t("driver-tip-desc")}
                    </span>
                </div>
            </div>

            <div className="ms-16 flex gap-2.5">
                {TIPS.map((tip) => (
                    <button
                        key={tip}
                        className={cn(
                            "flex size-10 items-center justify-center rounded-full border border-primary text-sm text-[rgba(64,64,64,1)]",
                            {
                                "bg-primary text-white": driverTip === tip,
                            },
                        )}
                        onClick={() => handleSetTip(tip)}
                    >
                        <span className="tracking-widest">
                            +{toArabic(tip.toString())}
                        </span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default DriverTip;
