import { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectCountryCode from "../components/modals/select-country-code";
import {
    ChevronDownIcon,
    CloseIcon,
    CommercialImageIcon,
    CompanyProfileIcon,
    CompanyTypeIcon,
    EmailIcon,
    MobileNumberIcon,
    StoreIcon,
    UAEAvailableIcon,
} from "../assets/icons";

export default function RegisterStore() {
    const { t } = useTranslation();

    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [uaeAvailable, setUaeAvailable] = useState("");
    const [companyProfile, setCompanyProfile] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [mobile, setMobile] = useState("");
    const [countryCode, setCountryCode] = useState("971");

    return (
        <div className="container mx-auto flex max-w-[852px] flex-col pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">{t("register-store")}</h2>
            <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                    <StoreIcon />
                    <input type="text" placeholder={t("store-name")} className="flex-1 focus:outline-none" />
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                    <EmailIcon />
                    <input type="text" placeholder={t("email")} className="flex-1 focus:outline-none" />
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                    <UAEAvailableIcon />
                    <input type="text" placeholder={t("UAE-available")} className="flex-1 focus:outline-none" />
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                    <CompanyProfileIcon />
                    <input type="text" placeholder={t("company-profile")} className="flex-1 focus:outline-none" />
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                    <MobileNumberIcon />

                    <input
                        type="text"
                        className="flex-1 focus:outline-none"
                        placeholder={t("mobile-number")}
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                    />

                    <button className="flex items-center transition hover:opacity-80" onClick={() => setIsCountryCodeSelecting(true)}>
                        +{countryCode}
                        <ChevronDownIcon />
                    </button>
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                    <CompanyTypeIcon />
                    <input type="text" className="flex-1 focus:outline-none" placeholder={t("company-type")} />
                </div>
            </div>

            <button className="my-6 flex items-center justify-center gap-2 rounded-lg border border-primary bg-white px-4 py-[18px] text-primary shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] transition hover:opacity-80">
                {t("commercial-image")}
                <CommercialImageIcon />
            </button>

            <p className="mx-auto max-w-[616px] text-center text-lg font-light text-[rgba(64,64,64,1)]">
                {t("register-store-desc")}{" "}
                <a href="/terms-conditions" className="text-primary">
                    {t("terms-and-conditions")}
                </a>
            </p>

            <button className="mx-auto mt-10 w-full max-w-[368px] rounded-lg bg-primary px-6 py-[18px] text-white transition hover:opacity-80">
                {t("registeration")}
            </button>

            {isCountryCodeSelecting && (
                <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed">
                    <div className="ReactModal__Content ReactModal__Content--after-open bg-white">
                        <div className="flex flex-col">
                            <div className="mb-4 flex">
                                <button className="ms-auto transition hover:scale-125" onClick={() => setIsCountryCodeSelecting(false)}>
                                    <CloseIcon />
                                </button>
                            </div>
                            <SelectCountryCode
                                setCountryCode={setCountryCode}
                                countryCode={countryCode}
                                setIsCountryCodeSelecting={setIsCountryCodeSelecting}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
