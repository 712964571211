import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { getSearchProducts } from "../actions/getSearchProducts";
import LoadingScreen from "../components/loading-screen";
import ProductCard from "../components/home/product-card";
import { useTranslation } from "react-i18next";
import NoDataIcon from "../assets/no-data-icon";

export default function Search() {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const q = searchParams.get("q");

    const { data, isFetching } = useQuery({
        queryKey: ["global", "offers"],
        queryFn: () => getSearchProducts({ q: q || "" }),
    });

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;
    return (
        <div className="container pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">{t("search")}</h2>
            {data.length === 0 ? (
                <NoDataIcon />
            ) : (
                <>
                    <h3 className="mb-6 text-2xl font-medium text-[rgba(23,23,23,1)]">{t("all-products")}</h3>
                    <div className="mb-8 grid gap-6 sm:grid-cols-3 xl:grid-cols-6">
                        {data.map((product) => (
                            <ProductCard key={product.id} product={product} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
