import React from "react";
import Accordion from "../../accordion"; // Ensure the import path is correct
import { useTranslation } from "react-i18next";

const TextAreaSection = ({ attribute }) => {
    const { t } = useTranslation();
    return (
        <Accordion title={attribute.name} key={attribute.id}>
            <textarea
                className="min-h-[88px] w-full border-[rgba(229,229,229,1)] outline-none placeholder:text-[rgba(189,189,189,1)]"
                placeholder={t("please-enter")}
            />
        </Accordion>
    );
};

export default TextAreaSection;
