import Logo from "../../assets/logo";
import { cn } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import useModalsStore from "../../store/modalsStore";
import useUserStore from "../../store/userStore";
import { setSession } from "../../lib/middleware";
import { AddressInfo } from "net";
import { useQuery } from "@tanstack/react-query";
import { getCart } from "../../actions/getCart";

export default function SelectPreviousAddressModal() {
    const {
        isSelectPreviousAddressModalOpen,
        setSelectPreviousAddressModalOpen,
    } = useModalsStore();
    const { isDeleteCartModalOpen, setDeleteCartModalOpen } = useModalsStore();
    const { t } = useTranslation();
    const user = useUserStore((state) => state.user);
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const currentAddress = useUserStore((state) => state.mainAddress);
    const setMainAddress = useUserStore((state) => state.setMainAddress);
    const handleContinue = () => {
        setSelectPreviousAddressModalOpen(false);
        setMapModalOpen(true);
    };
    const filteredAddress = user?.address.filter(
        (address) => address.region && address.emirate && address.branch,
    );
    const { data, isLoading, refetch } = useQuery({
        queryKey: ["global", "cart"],
        queryFn: getCart,
    });
    const handleChange = (address: any) => {
        if (currentAddress?.address === address.address) {
            setSelectPreviousAddressModalOpen(false);
            setMainAddress(address);
            setSession();
        } else {
            setSelectPreviousAddressModalOpen(false);
            refetch();
            if (data?.length !== 0) {
                setDeleteCartModalOpen(true);
            }
        }
    };

    return (
        <div
            className={cn("flex flex-col", {
                hidden: !isSelectPreviousAddressModalOpen,
            })}
        >
            <div className="mb-4 flex">
                <button
                    className="ms-auto transition hover:scale-125"
                    onClick={() => setSelectPreviousAddressModalOpen(false)}
                >
                    <svg
                        width="32"
                        height="33"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                            fill="#262626"
                        />
                    </svg>
                </button>
            </div>
            <div className="mb-8 flex justify-center">
                <Logo />
            </div>
            <span className="mb-8 text-center text-2xl font-medium text-foreground">
                {t("modals.select-previous-address")}
            </span>
            <div className="flex flex-col gap-4">
                {/* {user?.address?.map((address) => ( */}
                {filteredAddress?.map((address) => (
                    <button
                        onClick={() => {
                            handleChange(address);
                        }}
                        key={address.id}
                        className="flex gap-2 rounded-lg border border-[rgba(229,229,229,1)] p-4 transition hover:opacity-85"
                    >
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                stroke="#292D32"
                                strokeWidth="1.625"
                            />
                            <path
                                d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                stroke="#292D32"
                                strokeWidth="1.625"
                            />
                        </svg>
                        <div className="flex w-full justify-between">
                            <span style={{ textAlign: "left", color: "grey" }}>
                                <span
                                    style={{
                                        fontWeight: "lighter",
                                    }}
                                >
                                    {t("deliver-to")}
                                </span>
                                <br />
                                <p style={{ color: "rgba(10, 76, 38, 1)" }}>
                                    {address.name && address.name}
                                    {address.street_name &&
                                        (address.name
                                            ? ` - ${address.street_name}`
                                            : address.street_name)}
                                </p>
                                <p>
                                    {address.emirate?.name &&
                                        address.emirate.name}
                                    {address.region?.name &&
                                        (address.emirate?.name
                                            ? ` - ${address.region.name}`
                                            : address.region.name)}
                                </p>
                                <p>
                                    {address.building_number &&
                                        `${t("Building Number")} ${address.building_number}, `}
                                    {address.apartment_num &&
                                        `${t("Apartment Number")}` +
                                            ` ${address.apartment_num}`}
                                </p>
                            </span>
                        </div>
                    </button>
                ))}
            </div>
            <br />
            <button
                className={cn(
                    "rounded-xl bg-primary py-4 text-white transition hover:opacity-80",
                )}
                onClick={handleContinue}
            >
                {t("modals.new-address-btn")}
            </button>
        </div>
    );
}
