import { cn } from "../../lib/utils";
import useModalsStore from "../../store/modalsStore";
import LoadingSpinner from "../loading-spinner";

export default function Modal() {
    const {
        title,
        icon,
        confirmText,
        cancelText,
        onConfirm,
        onCancel,
        loading,
        variant,
        content,
    } = useModalsStore((state) => state.modalContent);
    const isModalOpen = useModalsStore((state) => state.isModalOpen);

    return (
        <div
            className={cn("flex flex-col sm:-m-4", {
                hidden: !isModalOpen,
            })}
        >
            <div className="mb-6">{icon}</div>
            <div className="mb-8 flex flex-col">
                <span className="text-lg text-[rgba(82,82,82,1)]">{title}</span>
                {content && <div className="mt-4">{content}</div>}
            </div>
            <div
                className={cn("flex justify-end gap-4", {
                    "grid md:grid-cols-2": variant === "wide-btns",
                })}
            >
                <button
                    className="rounded-md border border-primary px-6 py-[18px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] transition hover:bg-primary hover:text-white"
                    onClick={onCancel}
                >
                    {cancelText}
                </button>
                <button
                    className="flex items-center justify-center rounded-md border border-primary bg-primary px-6 py-[18px] text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] transition hover:opacity-80"
                    onClick={onConfirm}
                >
                    {loading ? <LoadingSpinner /> : confirmText}
                </button>
            </div>
        </div>
    );
}
