import axios from "axios";
import { Shop } from "../types/shop";

export async function getShop({ shopId }: { shopId: string }) {
    try {
        const response = await axios.get(`/shop/${shopId}`);
        const data = response.data;
        return data.data as Shop;
    } catch (error) {
        console.error(error);
    }
}
