import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import SelectCountryCode from "../components/modals/select-country-code";
import useUserStore, { Region } from "../store/userStore";
import axios from "axios";
import { cn } from "../lib/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/a11y";
import ChooseAddress from "../components/checkout/checkout-all/choose-address";
import { Chevron } from "../assets/icons";
import ChooseDeliverySlot from "../components/checkout/checkout-all/choose-delivery-slot";
import ErrorPage from "../error-page";
import useGiftsStore from "../store/giftsStore";
import { toast } from "react-toastify";
import LoadingSpinner from "../components/loading-spinner";

export default function CheckoutGifts() {
    const navigate = useNavigate();
    const { cart_id } = useParams();
    const { t, i18n } = useTranslation();
    const [toggle, setToggle] = useState(false);
    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);
    const [countryCode, setCountryCode] = useState("971");
    const [mobile, setMobile] = useState("");
    const [name, setName] = useState("");
    const [regions, setRegions] = useState<Region[]>([]);
    const emirate = useUserStore((state) => state.emirate);
    const [regionId, setRegionId] = useState("");

    async function fetchRegions() {
        try {
            const formData = new FormData();
            formData.append("emirate_id", emirate?.id?.toString() || "");
            const response = await axios.post("/regions", formData);
            setRegions(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (emirate?.name) fetchRegions();
    }, [emirate?.name]);

    const [loading, setLoading] = useState(false);
    const {
        address_id,
        deliveryDate,
        giftRecipientName,
        images,
        messageContent,
        messageSubject,
        recipientCountryCode,
        recipientEmirateId,
        selectDataFromRecipient,
        senderName,
    } = useGiftsStore();
    const handlePayingOff = async () => {
        // toast.error(t("use-app"));
        // return;

        setLoading(true);
        if (!address_id && !toggle) {
            toast.error(t("choose-address-error"));
            setLoading(false);
            return;
        } else if ((!name || !mobile || !regionId) && toggle) {
            toast.error(t("fill-fields"));
            setLoading(false);
            return;
        }
        try {
            // navigate("/checkout-gifts-payment");
            navigate("/checkout/gifts/payment");
            // const response = await axios.post("/orders/create", {
            //     cart_id,
            //     time_from: deliveryDate.time_from,
            //     time_to: deliveryDate.time_to,
            //     date: deliveryDate.date,
            //     address_id,
            //     gift_recipient_name: giftRecipientName,
            //     images,
            //     message_content: messageContent,
            //     message_subject: messageSubject,
            //     recipient_country_code: recipientCountryCode,
            //     recipient_emirate_id: recipientEmirateId,
            //     recipient_name: name,
            //     recipient_phone: mobile,
            //     select_data_from_recipient: selectDataFromRecipient,
            //     sender_name: senderName,
            //     region_id: regionId,
            // });

            // window.open(
            //     `${process.env.REACT_APP_PAYMENT_URL}?order_id=${response.data.data.id}`,
            //     "_self",
            // );
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    };

    if (!cart_id) return <ErrorPage />;
    return (
        <div className="mx-auto flex max-w-[852px] flex-col px-4 pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {t("delivery")}
            </h2>
            <div className="flex flex-col gap-4">
                <Link
                    to={"/checkout/gifts/card-content/" + cart_id}
                    className="flex items-center gap-4 rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4"
                >
                    <img src="/zabehatyIcons/qr.png" alt="qr" />
                    <span className="text-[rgba(38,38,38,1)]">
                        {t("gift-card-content")}
                    </span>
                    <Chevron />
                </Link>
                <div className="flex items-center gap-4 rounded-lg bg-custom-gray p-4 font-light text-[rgba(64,64,64,1)]">
                    {t("recipient")}
                </div>
                <div className="flex items-center justify-between gap-4 rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4">
                    <span className="text-[rgba(38,38,38,1)]">
                        {t("recipient-select")}
                    </span>

                    <label className="inline-flex cursor-pointer items-center">
                        <input
                            type="checkbox"
                            value={toggle.toString()}
                            onChange={() => setToggle(!toggle)}
                            className="peer sr-only"
                        />
                        <div className="peer relative h-8 w-14 rounded-full bg-[rgba(212,212,212,1)] after:absolute after:start-[4px] after:top-[3.5px] after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full"></div>
                    </label>
                </div>
                {toggle ? (
                    <>
                        <div className="flex items-center justify-between gap-4 rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4">
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder={t("recipient-name")}
                                className="w-full text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                            />
                        </div>
                        <div className="flex items-center justify-between gap-4 rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4">
                            <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.454 14.5505C18.108 19.2031 19.1637 13.8205 22.1269 16.7816C24.9836 19.6376 26.6256 20.2098 23.0061 23.8282C22.5527 24.1925 19.6722 28.576 9.54903 18.4556C-0.575399 8.33402 3.80551 5.45053 4.16996 4.99729C7.79817 1.36884 8.3605 3.0203 11.2172 5.87623C14.1804 8.83859 8.80009 9.89783 13.454 14.5505Z"
                                    fill="#D9F4E8"
                                    stroke="#0A4C26"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="h-full w-[1px] bg-[rgba(222,222,222,1)]" />
                            <div className="flex flex-1 flex-col gap-2 leading-none">
                                <span className="text-sm text-[rgba(64,64,64,1)]">
                                    {t("recipient-contact")}
                                </span>
                                <input
                                    type="text"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    placeholder={t("contact-number")}
                                    className="w-full text-lg text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                                />
                            </div>
                            <button
                                className="flex items-center font-medium transition hover:opacity-80"
                                onClick={() => setIsCountryCodeSelecting(true)}
                            >
                                +{countryCode}
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_258_154458)">
                                        <path
                                            d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                                            fill="#525252"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_258_154458">
                                            <rect
                                                width="11.5"
                                                height="11.5"
                                                fill="white"
                                                transform="translate(0.5 0.5)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                        <span className="text-lg font-medium text-[rgba(23,23,23,1)]">
                            {t("select-emirate")}
                        </span>
                        {regions && (
                            <Swiper
                                modules={[Navigation, A11y]}
                                spaceBetween={12}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 3,
                                    },
                                    640: {
                                        slidesPerView: 4,
                                    },
                                    1536: {
                                        slidesPerView: 6,
                                    },
                                }}
                                navigation={{
                                    enabled: true,
                                }}
                                dir={i18n.dir.toString()}
                                className="w-full overflow-hidden"
                            >
                                {regions.map((region) => (
                                    <SwiperSlide key={region.id}>
                                        <button
                                            key={region.id}
                                            className={cn(
                                                "w-full text-nowrap rounded-lg border border-[rgba(133,133,133,1)] px-4 py-5 text-sm text-[rgba(133,133,133,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]",
                                                {
                                                    "border-primary bg-primary text-white":
                                                        regionId === region.id,
                                                },
                                            )}
                                            onClick={() =>
                                                setRegionId(region.id)
                                            }
                                        >
                                            {region.name}
                                        </button>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </>
                ) : (
                    <ChooseAddress type="gifts" />
                )}
                <ChooseDeliverySlot
                    cart_id={cart_id}
                    className="border border-[rgba(229,229,229,1)] bg-white "
                    type="gifts"
                />
            </div>
            <div className="mt-14 flex justify-center">
                <button
                    onClick={handlePayingOff}
                    className="flex w-full max-w-[368px] items-center justify-center rounded-lg bg-primary px-6 py-[18px] text-center text-white transition hover:opacity-80"
                >
                    {loading ? <LoadingSpinner /> : t("next")}
                </button>
            </div>
            {isCountryCodeSelecting && (
                <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed">
                    <div className="ReactModal__Content ReactModal__Content--after-open bg-white">
                        <div className="flex flex-col">
                            <div className="mb-4 flex">
                                <button
                                    className="ms-auto transition hover:scale-125"
                                    onClick={() =>
                                        setIsCountryCodeSelecting(false)
                                    }
                                >
                                    <svg
                                        width="32"
                                        height="33"
                                        viewBox="0 0 32 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <SelectCountryCode
                                setCountryCode={setCountryCode}
                                countryCode={countryCode}
                                setIsCountryCodeSelecting={
                                    setIsCountryCodeSelecting
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
