import { useTranslation } from "react-i18next";
import SelectCountryCode from "../modals/select-country-code";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useUserStore from "../../store/userStore";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../loading-spinner";
import { md5 } from "js-md5";

export default function EditAccount() {
    const { t } = useTranslation();
    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);

    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [loading, setLoading] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const imageFile = event.target.files?.[0];
        if (!imageFile) return;

        const imageUrl = URL.createObjectURL(imageFile);
        setSelectedImage(imageUrl);
    };

    const triggerFileInput = () => {
        const fileInput = fileInputRef.current;
        if (fileInput) {
            fileInput.value = "";
            fileInput.click();
        }
    };

    useEffect(() => {
        setFirstName(user?.first_name || "");
        setLastName(user?.last_name || "");
        setEmail(user?.email || "");
        setMobile(user?.mobile || "");
        setCountryCode(user?.country_code || "");
    }, [user]);

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const formData = new FormData();

            first_name && formData.append("first_name", first_name);
            last_name && formData.append("last_name", last_name);
            email && formData.append("email", email);
            // mobile && formData.append("mobile", mobile);
            countryCode && formData.append("country_code", countryCode);
            fileInputRef.current?.files?.[0] &&
                formData.append("image", fileInputRef.current?.files?.[0]);
            const resp = await axios.post("/user/update", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "otp-token": md5(mobile),
                },
            });
            setUser(resp.data.data);

            toast.success(t("account.success-update"));
        } catch {
            toast.error(t("something-went-wrong"));
        }

        setLoading(false);
    };

    if (!user) return null;
    return (
        <div className="ms-auto flex max-w-[885px] flex-col items-center gap-10 px-4">
            <h2 className="text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {t("account.edit-profile")}
            </h2>

            <div
                className="relative size-[148px] cursor-pointer"
                onClick={triggerFileInput}
            >
                <input
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileInput}
                />
                <img
                    src={selectedImage || user.image}
                    alt={user.first_name}
                    className="h-full w-full rounded-full border border-[rgba(11,76,36,0.4)] object-cover"
                />
                <div className="absolute bottom-0 end-0 overflow-hidden rounded-full border-[4px] border-[rgba(238,245,241,1)] bg-primary p-2.5">
                    <svg
                        width="29"
                        height="28"
                        viewBox="0 0 29 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.0547 18.6641C15.9877 18.6641 17.5547 17.0971 17.5547 15.1641C17.5547 13.2311 15.9877 11.6641 14.0547 11.6641C12.1217 11.6641 10.5547 13.2311 10.5547 15.1641C10.5547 17.0971 12.1217 18.6641 14.0547 18.6641Z"
                            stroke="white"
                            strokeWidth="1.60142"
                        />
                        <path
                            d="M11.465 24.5001H16.6496C20.2908 24.5001 22.112 24.5 23.4198 23.6425C23.9841 23.2727 24.4701 22.7954 24.8501 22.2379C25.724 20.9546 25.724 19.1661 25.724 15.5914C25.724 12.0156 25.724 10.2282 24.8501 8.94489C24.4701 8.38735 23.9841 7.91006 23.4198 7.54022C22.5798 6.98839 21.5275 6.79122 19.9163 6.72122C19.1475 6.72122 18.486 6.14955 18.3355 5.40872C18.2204 4.86607 17.9216 4.37976 17.4894 4.032C17.0573 3.68423 16.5183 3.49633 15.9636 3.50005H12.151C10.9983 3.50005 10.0055 4.29922 9.77913 5.40872C9.62863 6.14955 8.96713 6.72122 8.19829 6.72122C6.58829 6.79122 5.53596 6.98955 4.69479 7.54022C4.13085 7.91013 3.64523 8.38742 3.26563 8.94489C2.39062 10.2282 2.39062 12.0156 2.39062 15.5914C2.39062 19.1661 2.39063 20.9534 3.26446 22.2379C3.64246 22.7932 4.12779 23.2704 4.69479 23.6425C6.00263 24.5 7.82379 24.5001 11.465 24.5001Z"
                            stroke="white"
                            strokeWidth="1.60142"
                        />
                        <path
                            d="M22.2214 11.6641H21.0547"
                            stroke="white"
                            strokeWidth="1.60142"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            </div>
            <div className="flex flex-col">
                <span className="mb-4 text-xl font-medium text-[rgba(38,38,38,1)]">
                    {t("account.profile-info")}
                </span>
                <div className="mb-4 grid gap-4 sm:grid-cols-2">
                    <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-[rgba(245,245,245,1)] px-4 py-[18px]">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1596 11.7567C12.0596 11.7467 11.9396 11.7467 11.8296 11.7567C9.44957 11.6767 7.55957 9.72672 7.55957 7.32672C7.55957 4.87672 9.53957 2.88672 11.9996 2.88672C14.4496 2.88672 16.4396 4.87672 16.4396 7.32672C16.4296 9.72672 14.5396 11.6767 12.1596 11.7567Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.15973 15.4467C4.73973 17.0667 4.73973 19.7067 7.15973 21.3167C9.90973 23.1567 14.4197 23.1567 17.1697 21.3167C19.5897 19.6967 19.5897 17.0567 17.1697 15.4467C14.4297 13.6167 9.91973 13.6167 7.15973 15.4467Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <input
                            type="text"
                            className="h-full flex-1 bg-transparent pb-0.5 text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                            placeholder={t("modals.first-name")}
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-[rgba(245,245,245,1)] px-4 py-[18px]">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1596 11.7567C12.0596 11.7467 11.9396 11.7467 11.8296 11.7567C9.44957 11.6767 7.55957 9.72672 7.55957 7.32672C7.55957 4.87672 9.53957 2.88672 11.9996 2.88672C14.4496 2.88672 16.4396 4.87672 16.4396 7.32672C16.4296 9.72672 14.5396 11.6767 12.1596 11.7567Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.15973 15.4467C4.73973 17.0667 4.73973 19.7067 7.15973 21.3167C9.90973 23.1567 14.4197 23.1567 17.1697 21.3167C19.5897 19.6967 19.5897 17.0567 17.1697 15.4467C14.4297 13.6167 9.91973 13.6167 7.15973 15.4467Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <input
                            type="text"
                            className="h-full flex-1 bg-transparent pb-0.5 text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                            placeholder={t("modals.last-name")}
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-6 grid gap-4 sm:grid-cols-2">
                    <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-[rgba(245,245,245,1)] px-4 py-[18px]">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1596 11.7567C12.0596 11.7467 11.9396 11.7467 11.8296 11.7567C9.44957 11.6767 7.55957 9.72672 7.55957 7.32672C7.55957 4.87672 9.53957 2.88672 11.9996 2.88672C14.4496 2.88672 16.4396 4.87672 16.4396 7.32672C16.4296 9.72672 14.5396 11.6767 12.1596 11.7567Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.15973 15.4467C4.73973 17.0667 4.73973 19.7067 7.15973 21.3167C9.90973 23.1567 14.4197 23.1567 17.1697 21.3167C19.5897 19.6967 19.5897 17.0567 17.1697 15.4467C14.4297 13.6167 9.91973 13.6167 7.15973 15.4467Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <input
                            type="text"
                            className="h-full flex-1 bg-transparent pb-0.5 text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                            placeholder={t("modals.email")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-[rgba(245,245,245,1)] px-4 py-[18px]">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1596 11.7567C12.0596 11.7467 11.9396 11.7467 11.8296 11.7567C9.44957 11.6767 7.55957 9.72672 7.55957 7.32672C7.55957 4.87672 9.53957 2.88672 11.9996 2.88672C14.4496 2.88672 16.4396 4.87672 16.4396 7.32672C16.4296 9.72672 14.5396 11.6767 12.1596 11.7567Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.15973 15.4467C4.73973 17.0667 4.73973 19.7067 7.15973 21.3167C9.90973 23.1567 14.4197 23.1567 17.1697 21.3167C19.5897 19.6967 19.5897 17.0567 17.1697 15.4467C14.4297 13.6167 9.91973 13.6167 7.15973 15.4467Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <input
                            type="text"
                            className="h-full flex-1 bg-transparent pb-0.5 text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                            placeholder={t("modals.mobile")}
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />

                        <button
                            className="flex items-center transition hover:opacity-80"
                            onClick={() => setIsCountryCodeSelecting(true)}
                        >
                            +{countryCode}
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_258_154458)">
                                    <path
                                        d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                                        fill="#525252"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_258_154458">
                                        <rect
                                            width="11.5"
                                            height="11.5"
                                            fill="white"
                                            transform="translate(0.5 0.5)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
                <Link
                    to="/change-password"
                    className="self-start font-light text-[rgba(15,23,42,1)] transition hover:opacity-80"
                >
                    {t("account.change-password")}
                </Link>
            </div>
            <div className="flex w-full max-w-[368px] flex-col gap-4">
                <button
                    onClick={handleUpdate}
                    className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                >
                    {loading ? <LoadingSpinner /> : t("account.update")}
                </button>
                <button className="rounded-lg border border-[rgba(240,61,67,1)] px-4 py-[18px] text-[rgba(240,61,67,1)] transition hover:bg-[rgba(240,61,67,1)] hover:text-white">
                    {t("account.delete-account")}
                </button>
            </div>
            {isCountryCodeSelecting && (
                <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed">
                    <div className="ReactModal__Content ReactModal__Content--after-open bg-white">
                        <div className="flex flex-col">
                            <div className="mb-4 flex">
                                <button
                                    className="ms-auto transition hover:scale-125"
                                    onClick={() =>
                                        setIsCountryCodeSelecting(false)
                                    }
                                >
                                    <svg
                                        width="32"
                                        height="33"
                                        viewBox="0 0 32 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <SelectCountryCode
                                setCountryCode={setCountryCode}
                                countryCode={countryCode}
                                setIsCountryCodeSelecting={
                                    setIsCountryCodeSelecting
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
