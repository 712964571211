import axios from "axios";
import HomePage from "../types/home-page";

export async function getHomePage() {
    try {console.log(axios);
        const response = await axios.get("/home");
        const data = response.data;

        return data.data as HomePage;
    } catch (error) {
        console.error(error);
    }
}
