import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function MyPoints() {
    const { t } = useTranslation();
    const [points, setPoints] = useState("");
    return (
        <div className="ms-auto flex max-w-[917px] flex-col gap-10 px-4 ">
            <h2 className="text-center text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {t("account.my-points")}
            </h2>
            <div className="flex gap-2.5 rounded-xl bg-[rgba(245,245,245,1)] p-4">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21.3883 8.79153L15.1076 7.83215L12.2925 1.83583C12.0822 1.38806 11.3476 1.38806 11.1373 1.83583L8.32304 7.83215L2.04238 8.79153C1.52652 8.8707 1.32051 9.49808 1.6823 9.86839L6.24509 14.5452L5.16654 21.157C5.08056 21.6823 5.64154 22.0764 6.10548 21.8185L11.7153 18.7182L17.3252 21.8193C17.7849 22.0747 18.351 21.6882 18.2641 21.1579L17.1856 14.5461L21.7484 9.86924C22.1102 9.49808 21.9033 8.8707 21.3883 8.79153Z"
                        fill="#FFC107"
                    />
                </svg>
                <div className="flex flex-1 flex-col gap-3 text-[rgba(38,38,38,1)]">
                    <div className="flex justify-between text-lg font-medium">
                        <span>{t("account.my-points")}</span>
                        <span>1000</span>
                    </div>
                    <div className="flex justify-between">
                        <span>50 {t("account.points")} =</span>
                        <span>1 {t("AED")}</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col rounded-xl bg-[rgba(245,245,245,1)] p-4">
                <span className="mb-2 text-lg font-medium text-[rgba(38,38,38,1)]">
                    {t("account.use-points")}
                </span>
                <span className="mb-6 font-light">
                    {t("account.use-points-desc")} ={" "}
                    <span className="text-[rgba(240,61,67,1)]">
                        {t("account.minimum-points")}
                    </span>
                </span>
                <div className="flex flex-col items-center rounded-lg bg-[rgba(207,219,211,1)] p-6">
                    <span className="text-center text-[rgba(38,38,38,1)]">
                        {t("account.points-card")}
                    </span>
                    <div>
                        <svg
                            width="79"
                            height="79"
                            viewBox="0 0 79 79"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_258_203342)">
                                <path
                                    d="M48.7833 56.2719L43.1411 57.4276C50.6417 45.0977 48.4124 37.7504 46.4971 34.5955C46.5896 34.3232 46.7414 34.0894 46.8339 33.8171C47.7968 31.0355 49.8998 21.4327 35.8992 12.3413L35.8398 12.3027L35.0808 13.4717L35.1401 13.5102C48.0729 21.9082 46.3446 30.6742 45.4363 33.2419C43.4594 30.9611 40.7662 30.0432 38.7709 30.9079C37.0081 31.6744 36.204 33.562 36.5272 36.1816C36.9406 39.4414 38.846 40.0139 39.629 40.1069C41.6648 40.3487 44.0942 38.6856 45.6076 36.0953C47.6987 40.2783 47.5195 47.3081 41.8953 56.6186L40.6772 51.0913L39.3153 51.3702L41.0677 59.3219L49.0445 57.688L48.7833 56.2719ZM39.7782 38.7082C38.5254 38.5594 38.0696 37.1832 37.9104 35.9996C37.6396 34.0788 38.1994 32.6973 39.3619 32.2058C40.3892 31.7926 41.6634 32.0384 42.8499 32.8089C43.3839 33.1556 43.9392 33.5992 44.4185 34.1598C44.5586 34.3338 44.6986 34.5079 44.8386 34.6819C43.4439 37.3492 41.2849 38.8556 39.7782 38.7082Z"
                                    fill="#0A4C26"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_258_203342">
                                    <rect
                                        width="56.4717"
                                        height="56.4717"
                                        fill="white"
                                        transform="translate(31.5) rotate(32.998)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="flex w-full max-w-[305px] overflow-hidden rounded-lg border border-white bg-white">
                        <div className="flex flex-1 items-center gap-2 px-3 py-3.5">
                            <input
                                type="text"
                                className="h-3 w-0 flex-1 bg-transparent pb-0.5 text-xs font-light text-[rgba(38,38,38,1)] placeholder:text-primary focus:outline-none"
                                placeholder={
                                    t("account.you-have") +
                                    " 1500 " +
                                    t("account.points") +
                                    ` (${150 + " " + t("AED")})`
                                }
                                value={points}
                                onChange={(e) => setPoints(e.target.value)}
                            />
                        </div>
                        <button className="bg-primary px-[25px] text-sm text-white transition hover:opacity-80">
                            {t("use")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
