import React from 'react';
import { useTranslation } from "react-i18next";
import TextAreaSection from './AttributesSections/TextAreaSection';
import WithoutSection from './AttributesSections/WithoutSection';
import CuttingsSection from './AttributesSections/CuttingsSection';
import PackagingSection from './AttributesSections/PackagingSection';

const Attributes = ({ data,cartAttributes,setCartAttributes }) => {
    const { i18n, t } = useTranslation();
    const pushAttribute = (attribute_id,value_id,multi_values=false) => {
        if(multi_values){
            if( cartAttributes.hasOwnProperty(attribute_id)){
                var value_id = cartAttributes[attribute_id].value_id.includes(value_id) ? 
                    cartAttributes[attribute_id].value_id.filter(val => val !== value_id) :
                    [...cartAttributes[attribute_id].value_id, value_id];
            }
            else
                var value_id = [value_id];
        }
        cartAttributes[attribute_id] = {
            attribute_id,
            value_id
        };
        
        setCartAttributes({...cartAttributes});            
    };
    return (
        <>
            {data.attributes &&
                data.attributes.length > 0 &&
                data.attributes.map((attribute) => {
                    switch (attribute.section_name) {
                        case "textarea":
                            return (
                                <TextAreaSection
                                    key={attribute.id}
                                    attribute={attribute}
                                />
                            );
                        case "without":
                            return (
                                <WithoutSection
                                    key={attribute.id}
                                    attribute={attribute}
                                    cartAttributes = {cartAttributes}
                                    pushAttribute={pushAttribute}
                                />
                            );
                        case "cutting":
                            return (
                                <CuttingsSection
                                    key={attribute.id}
                                    data={data}
                                    attribute={attribute}
                                    cartAttributes = {cartAttributes}
                                    pushAttribute={pushAttribute}
                                />
                            );
                        case "packaging":
                            return (
                                <PackagingSection
                                    key={attribute.id}
                                    data={data}
                                    attribute={attribute}
                                    cartAttributes = {cartAttributes}
                                    pushAttribute={pushAttribute}
                                />
                            );
                        default:
                            return null;
                    }
                })}
        </>
    );
};

export default Attributes;
