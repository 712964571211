import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import LoadingSpinner from "../loading-spinner";

export default function ChangePassword() {
    const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);
        if (newPassword !== confirmNewPassword) {
            setLoading(false);
            toast.error(t("modals.errors.confirm-password"));
            return;
        }
        try {
            const formData = new FormData();
            formData.append("old_password", password);
            formData.append("password", newPassword);
            await axios.post("/user/update_password", formData);
            toast.success(t("messages.password-reset-success"));
        } catch {
            toast.error(t("something-went-wrong"));
        }
        setLoading(false);
    };

    return (
        <div className="mx-auto flex max-w-[852px] flex-col gap-10 px-4 pb-28 pt-14">
            <h2 className="text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">{t("account.change-password")}</h2>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8298 8.59781C16.3403 8.10844 15.6895 7.83906 14.9975 7.83906H14.81V5.26344C14.81 2.6325 12.6697 0.492188 10.0389 0.492188H9.8625C7.23172 0.492188 5.09125 2.6325 5.09125 5.26344V7.83891H4.90375C4.21172 7.83891 3.56094 8.10844 3.07141 8.59781C2.58203 9.08734 2.3125 9.73812 2.3125 10.4303V16.9072C2.3125 17.5994 2.58203 18.2502 3.07141 18.7394C3.56078 19.2289 4.21156 19.4984 4.90375 19.4984H14.9975C15.6897 19.4984 16.3405 19.2289 16.83 18.7394C17.3194 18.25 17.5889 17.5992 17.5889 16.9072V10.4303C17.5889 9.73828 17.3194 9.0875 16.8298 8.59781ZM6.34125 5.26344C6.34125 3.32187 7.92078 1.74219 9.8625 1.74219H10.0389C11.9805 1.74219 13.56 3.32187 13.56 5.26344V7.83891H6.34125V5.26344ZM16.3389 16.9072C16.3389 17.2602 16.1956 17.6058 15.9461 17.8555C15.6927 18.1089 15.3558 18.2484 14.9975 18.2484H4.90375C4.54547 18.2484 4.20875 18.1089 3.95516 17.8553C3.70187 17.6022 3.56234 17.2655 3.56234 16.9072V10.4303C3.56234 10.072 3.70187 9.735 3.95516 9.48172C4.20859 9.22844 4.54531 9.08891 4.90359 9.08891H14.9973C15.3556 9.08891 15.6925 9.22844 15.9458 9.48156C16.1992 9.73516 16.3387 10.072 16.3387 10.4303L16.3389 16.9072Z"
                            fill="#404040"
                        />
                        <path
                            d="M9.94922 11.9414C9.60406 11.9414 9.32422 12.2212 9.32422 12.5664V14.7683C9.32422 15.1134 9.60406 15.3933 9.94922 15.3933C10.2944 15.3933 10.5742 15.1134 10.5742 14.7683V12.5664C10.5742 12.2212 10.2944 11.9414 9.94922 11.9414Z"
                            fill="#404040"
                        />
                    </svg>

                    <input
                        type={showPassword ? "text" : "password"}
                        className="flex-1 pb-0.5 text-[rgba(64,64,64,1)] placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                        placeholder={t("modals.old-password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <button className="ms-auto" onClick={() => setShowPassword(!showPassword)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6854 9.00043C11.6854 10.4854 10.4854 11.6854 9.00043 11.6854C7.51543 11.6854 6.31543 10.4854 6.31543 9.00043C6.31543 7.51543 7.51543 6.31543 9.00043 6.31543C10.4854 6.31543 11.6854 7.51543 11.6854 9.00043Z"
                                stroke="#404040"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.99988 15.2025C11.6474 15.2025 14.1149 13.6425 15.8324 10.9425C16.5074 9.88504 16.5074 8.10754 15.8324 7.05004C14.1149 4.35004 11.6474 2.79004 8.99988 2.79004C6.35238 2.79004 3.88488 4.35004 2.16738 7.05004C1.49238 8.10754 1.49238 9.88504 2.16738 10.9425C3.88488 13.6425 6.35238 15.2025 8.99988 15.2025Z"
                                stroke="#404040"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8298 8.59781C16.3403 8.10844 15.6895 7.83906 14.9975 7.83906H14.81V5.26344C14.81 2.6325 12.6697 0.492188 10.0389 0.492188H9.8625C7.23172 0.492188 5.09125 2.6325 5.09125 5.26344V7.83891H4.90375C4.21172 7.83891 3.56094 8.10844 3.07141 8.59781C2.58203 9.08734 2.3125 9.73812 2.3125 10.4303V16.9072C2.3125 17.5994 2.58203 18.2502 3.07141 18.7394C3.56078 19.2289 4.21156 19.4984 4.90375 19.4984H14.9975C15.6897 19.4984 16.3405 19.2289 16.83 18.7394C17.3194 18.25 17.5889 17.5992 17.5889 16.9072V10.4303C17.5889 9.73828 17.3194 9.0875 16.8298 8.59781ZM6.34125 5.26344C6.34125 3.32187 7.92078 1.74219 9.8625 1.74219H10.0389C11.9805 1.74219 13.56 3.32187 13.56 5.26344V7.83891H6.34125V5.26344ZM16.3389 16.9072C16.3389 17.2602 16.1956 17.6058 15.9461 17.8555C15.6927 18.1089 15.3558 18.2484 14.9975 18.2484H4.90375C4.54547 18.2484 4.20875 18.1089 3.95516 17.8553C3.70187 17.6022 3.56234 17.2655 3.56234 16.9072V10.4303C3.56234 10.072 3.70187 9.735 3.95516 9.48172C4.20859 9.22844 4.54531 9.08891 4.90359 9.08891H14.9973C15.3556 9.08891 15.6925 9.22844 15.9458 9.48156C16.1992 9.73516 16.3387 10.072 16.3387 10.4303L16.3389 16.9072Z"
                            fill="#404040"
                        />
                        <path
                            d="M9.94922 11.9414C9.60406 11.9414 9.32422 12.2212 9.32422 12.5664V14.7683C9.32422 15.1134 9.60406 15.3933 9.94922 15.3933C10.2944 15.3933 10.5742 15.1134 10.5742 14.7683V12.5664C10.5742 12.2212 10.2944 11.9414 9.94922 11.9414Z"
                            fill="#404040"
                        />
                    </svg>

                    <input
                        type={showNewPassword ? "text" : "password"}
                        className="flex-1 pb-0.5 text-[rgba(64,64,64,1)] placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                        placeholder={t("modals.new-password")}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />

                    <button className="ms-auto" onClick={() => setShowNewPassword(!showNewPassword)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6854 9.00043C11.6854 10.4854 10.4854 11.6854 9.00043 11.6854C7.51543 11.6854 6.31543 10.4854 6.31543 9.00043C6.31543 7.51543 7.51543 6.31543 9.00043 6.31543C10.4854 6.31543 11.6854 7.51543 11.6854 9.00043Z"
                                stroke="#404040"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.99988 15.2025C11.6474 15.2025 14.1149 13.6425 15.8324 10.9425C16.5074 9.88504 16.5074 8.10754 15.8324 7.05004C14.1149 4.35004 11.6474 2.79004 8.99988 2.79004C6.35238 2.79004 3.88488 4.35004 2.16738 7.05004C1.49238 8.10754 1.49238 9.88504 2.16738 10.9425C3.88488 13.6425 6.35238 15.2025 8.99988 15.2025Z"
                                stroke="#404040"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
                <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8298 8.59781C16.3403 8.10844 15.6895 7.83906 14.9975 7.83906H14.81V5.26344C14.81 2.6325 12.6697 0.492188 10.0389 0.492188H9.8625C7.23172 0.492188 5.09125 2.6325 5.09125 5.26344V7.83891H4.90375C4.21172 7.83891 3.56094 8.10844 3.07141 8.59781C2.58203 9.08734 2.3125 9.73812 2.3125 10.4303V16.9072C2.3125 17.5994 2.58203 18.2502 3.07141 18.7394C3.56078 19.2289 4.21156 19.4984 4.90375 19.4984H14.9975C15.6897 19.4984 16.3405 19.2289 16.83 18.7394C17.3194 18.25 17.5889 17.5992 17.5889 16.9072V10.4303C17.5889 9.73828 17.3194 9.0875 16.8298 8.59781ZM6.34125 5.26344C6.34125 3.32187 7.92078 1.74219 9.8625 1.74219H10.0389C11.9805 1.74219 13.56 3.32187 13.56 5.26344V7.83891H6.34125V5.26344ZM16.3389 16.9072C16.3389 17.2602 16.1956 17.6058 15.9461 17.8555C15.6927 18.1089 15.3558 18.2484 14.9975 18.2484H4.90375C4.54547 18.2484 4.20875 18.1089 3.95516 17.8553C3.70187 17.6022 3.56234 17.2655 3.56234 16.9072V10.4303C3.56234 10.072 3.70187 9.735 3.95516 9.48172C4.20859 9.22844 4.54531 9.08891 4.90359 9.08891H14.9973C15.3556 9.08891 15.6925 9.22844 15.9458 9.48156C16.1992 9.73516 16.3387 10.072 16.3387 10.4303L16.3389 16.9072Z"
                            fill="#404040"
                        />
                        <path
                            d="M9.94922 11.9414C9.60406 11.9414 9.32422 12.2212 9.32422 12.5664V14.7683C9.32422 15.1134 9.60406 15.3933 9.94922 15.3933C10.2944 15.3933 10.5742 15.1134 10.5742 14.7683V12.5664C10.5742 12.2212 10.2944 11.9414 9.94922 11.9414Z"
                            fill="#404040"
                        />
                    </svg>

                    <input
                        type={showConfirmNewPassword ? "text" : "password"}
                        className="flex-1 pb-0.5 text-[rgba(64,64,64,1)] placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                        placeholder={t("modals.confirm-password")}
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />

                    <button className="ms-auto" onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6854 9.00043C11.6854 10.4854 10.4854 11.6854 9.00043 11.6854C7.51543 11.6854 6.31543 10.4854 6.31543 9.00043C6.31543 7.51543 7.51543 6.31543 9.00043 6.31543C10.4854 6.31543 11.6854 7.51543 11.6854 9.00043Z"
                                stroke="#404040"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.99988 15.2025C11.6474 15.2025 14.1149 13.6425 15.8324 10.9425C16.5074 9.88504 16.5074 8.10754 15.8324 7.05004C14.1149 4.35004 11.6474 2.79004 8.99988 2.79004C6.35238 2.79004 3.88488 4.35004 2.16738 7.05004C1.49238 8.10754 1.49238 9.88504 2.16738 10.9425C3.88488 13.6425 6.35238 15.2025 8.99988 15.2025Z"
                                stroke="#404040"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="mx-auto flex w-full max-w-[368px] flex-col gap-4">
                <button
                    className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                    onClick={handleUpdate}
                >
                    {loading ? <LoadingSpinner /> : t("account.update")}
                </button>
            </div>
        </div>
    );
}
