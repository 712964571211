import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getCart } from "../actions/getCart";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { cn, toArabic } from "../lib/utils";
import AddToFavouriteButton from "../components/home/add-to-favourite-button";
import LoadingScreen from "../components/loading-screen";
import { CartItem } from "../types/cart";
import QuantityPrice from "../components/cart/quantity-price";
import { Link, useNavigate } from "react-router-dom";
import useModalsStore from "../store/modalsStore";
import NoDataIcon from "../assets/no-data-icon";
import { Delete, Warning } from "../assets/icons";
import { link } from "fs";

const tabButtons = [
    {
        key: "all",
        filter: (item: CartItem) =>
            item.department.id !== 168 &&
            item.department.id !== 2 &&
            item.department.id !== 3,
    },
    {
        key: "gifts",
        filter: (item: CartItem) => item.department.id === 168,
    },
    {
        key: "sacrifices",
        filter: (item: CartItem) => item.department.id === 2,
    },
    {
        key: "donations",
        filter: (item: CartItem) => item.department.id === 3,
    },
];

export default function Cart() {
    const { t } = useTranslation();
    const setModalOpen = useModalsStore((state) => state.setModalOpen);
    const setModalContent = useModalsStore((state) => state.setModalContent);
    const resetModalContent = useModalsStore(
        (state) => state.resetModalContent,
    );
    const { data, isLoading, refetch } = useQuery({
        queryKey: ["global", "cart"],
        queryFn: getCart,
    });

    const [tab, setTab] = useState<string>("all");
    const [filteredCartItems, setFilteredCartItems] = useState<CartItem[]>([]);
    const [totalCost, setTotalCost] = useState(0);
    const navigate = useNavigate();

    const handleRedirectSacrifices = () => {
        setModalContent({
            title: (
                <>
                    {t("select")}
                    <strong> {t("delivery-method")} </strong>
                    {t("to-your-order")}
                </>
            ),
            confirmText: t("delivery"),
            cancelText: t("pickup-points"),
            onConfirm: () => {
                navigate(`/checkout/sacrifices?address=true`);
                setModalOpen(false);
                resetModalContent();
            },
            onCancel: () => {
                navigate(`/checkout/sacrifices?address=false`);
                setModalOpen(false);
                resetModalContent();
            },
            loading: false,
            icon: <Warning />,
        });
        setModalOpen(true);
    };

    useEffect(() => {
        if (data) {
            if (tab === "all") {
                const filteredCartItems = data.filter(
                    (item) =>
                        item.department.id !== 168 &&
                        item.department.id !== 2 &&
                        item.department.id !== 3,
                );
                setFilteredCartItems(filteredCartItems);
                setTotalCost(
                    filteredCartItems.reduce(
                        (acc: number, item: any) => acc + +item.subtotal,
                        0,
                    ),
                );
            } else if (tab === "gifts") {
                const filteredCartItems = data.filter(
                    (item) => item.department.id === 168,
                );
                setFilteredCartItems(filteredCartItems);
                setTotalCost(
                    filteredCartItems.reduce(
                        (acc: number, item: any) => acc + +item.subtotal,
                        0,
                    ),
                );
            } else if (tab === "sacrifices") {
                const filteredCartItems = data.filter(
                    (item) => item.department.id === 2,
                );
                setFilteredCartItems(filteredCartItems);
                setTotalCost(
                    filteredCartItems.reduce(
                        (acc: number, item: any) => acc + +item.subtotal,
                        0,
                    ),
                );
            } else if (tab === "donations") {
                const filteredCartItems = data.filter(
                    (item) => item.department.id === 3,
                );
                setFilteredCartItems(filteredCartItems);
                setTotalCost(
                    filteredCartItems.reduce(
                        (acc: number, item: any) => acc + +item.subtotal,
                        0,
                    ),
                );
            }
        }
    }, [data, tab]);

    const deleteFromCart = async (cart_detail_id: number) => {
        // setModalContent({
        //     ...modalContent,
        //     loading: true,
        // });
        try {
            const formData = new FormData();
            formData.append("cart_detail_id", cart_detail_id.toString());
            await axios.post("/cart/delete", formData);
            await refetch();
            resetModalContent();
            setModalOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteFromCart = (cart_detail_id: number) => {
        setModalContent({
            title: t("delete-product"),
            confirmText: t("yes"),
            cancelText: t("no"),
            onConfirm: () => {
                deleteFromCart(cart_detail_id);
            },
            onCancel: () => {
                setModalOpen(false);
            },
            loading: false,
            icon: <Warning />,
        });

        setModalOpen(true);
    };

    const handleTabClick = (
        key: string,
        filter: (item: CartItem) => boolean,
    ) => {
        if (!data) return;
        setTab(key);
        setFilteredCartItems(data.filter(filter));
    };

    const handleCheckoutNavigation = () => {
        if (tab === "all" && filteredCartItems.length === 1) {
            navigate(`/checkout/single`);
        } else if (tab === "all" && filteredCartItems.length > 1) {
            navigate(`/checkout/multi`);
        } else if (tab === "sacrifices") {
            handleRedirectSacrifices();
        } else if (tab === "gifts") {
            navigate(`/checkout/gifts/${filteredCartItems[0].id}`);
        } else if (tab === "donations") {
            navigate("/checkout/donations");
        }
    };

    if (isLoading) return <LoadingScreen />;
    if (!data) return null;

    return (
        <div className="mx-auto flex max-w-[852px] flex-col px-4 pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {t("cart")}
            </h2>

            <div className="mb-12 flex justify-center gap-2">
                {tabButtons.map(({ key, filter }) => (
                    <button
                        key={key}
                        className={cn(
                            "rounded-md border border-[rgba(212,212,212,1)] bg-white px-7 py-4 font-medium",
                            {
                                "border-primary text-primary": tab === key,
                            },
                        )}
                        onClick={() => handleTabClick(key, filter)}
                    >
                        {t(key)}
                    </button>
                ))}
            </div>
            {filteredCartItems.length > 0 ? (
                <div className="flex flex-col gap-6">
                    {filteredCartItems.map((item, idx) => (
                        <div key={idx} className="contents">
                            <div className="flex flex-col rounded-xl bg-white p-4 shadow-[0px_4px_4px_0px_rgba(182,182,182,0.15)]">
                                <span className="mb-4 text-xl font-semibold text-[rgba(38,38,38,1)]">
                                    {t("delivery-no")} {idx + 1}
                                </span>
                                <div className="flex flex-col gap-4">
                                    {item.details.map((detail) => (
                                        <div
                                            key={detail.id}
                                            className="flex gap-2 rounded-lg border border-[rgba(245,245,245,1)] bg-[rgba(250,250,250,1)] p-4 max-md:flex-col"
                                        >
                                            <div>
                                                <img
                                                    src={detail.product.image}
                                                    alt={detail.product.name}
                                                    className="h-[104px] w-[130px] rounded-[4px] object-cover max-xl:h-[250px] max-xl:w-full"
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <div className="mb-5 flex items-start justify-between">
                                                    <div className="flex flex-col gap-1.5">
                                                        <span className="text-lg font-medium text-primary">
                                                            {
                                                                detail.product
                                                                    .name
                                                            }
                                                        </span>
                                                        <span className="text-sm font-light">
                                                            {
                                                                detail.category
                                                                    .name
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="flex gap-2.5">
                                                        <AddToFavouriteButton
                                                            productId={
                                                                detail.product
                                                                    .id
                                                            }
                                                            in_favourite={
                                                                detail.product
                                                                    .in_favourite
                                                            }
                                                        />
                                                        <button
                                                            onClick={() =>
                                                                handleDeleteFromCart(
                                                                    detail.id,
                                                                )
                                                            }
                                                            className="rounded-full bg-white p-1 shadow-[0px_2.71px_5.41px_0px_rgba(26,26,26,0.16)] transition hover:scale-125"
                                                        >
                                                            <Delete />
                                                        </button>
                                                    </div>
                                                </div>
                                                <QuantityPrice
                                                    quantity={+detail.quantity}
                                                    price={
                                                        +detail.product.price
                                                    }
                                                    cart_detail_id={detail.id}
                                                    setTotalCost={setTotalCost}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="mb-14 flex items-center justify-between text-2xl font-medium text-[rgba(23,23,23,1)]">
                        <span>{t("price")}</span>
                        <span>
                            {toArabic(totalCost.toFixed(2))} {t("AED")}
                        </span>
                    </div>
                    <button
                        onClick={handleCheckoutNavigation}
                        className="rounded-lg bg-primary px-6 py-[18px] text-center text-white transition hover:opacity-80"
                    >
                        {t("complete-order")}
                    </button>
                </div>
            ) : (
                <NoDataIcon />
            )}
        </div>
    );
}
