import { useState } from "react";
import { DriverSvg } from "../../../assets/DriverSvg";
import { useTranslation } from "react-i18next";
import { Buthcering, Chevron, DeliveryCar } from "../../../assets/icons";
import SelectionModal from "../../cart/selection-modal";
import useSlaughterStore from "../../../store/slaughterStore";

export default function ChooseSlaughterDay({ cart_id }: { cart_id: string }) {
    const { t } = useTranslation();

    const { slaughterDay, deliveryDay, setSlaughterDay, setDeliveryDay } =
        useSlaughterStore((state) => state);
    const [isChoosingSlaughterDay, setIsChoosingSlaughterDay] = useState(false);
    const [isChoosingDeliveryDay, setIsChoosingDeliveryDay] = useState(false);
    return (
        <div className="flex flex-col gap-4 ">
            <span className="text-xl font-medium text-[rgba(23,23,23,1)]">
                {t("select-slaughter-day")}
            </span>
            <button
                onClick={() => {
                    setIsChoosingSlaughterDay(true);
                }}
                className="flex items-center gap-4 rounded-lg border border-[rgba(245,245,245,1)] bg-[rgba(250,250,250,1)] p-4 transition hover:opacity-80"
            >
                <Buthcering />
                {slaughterDay ? (
                    <div className="flex flex-col items-start gap-2 leading-none">
                        <span className="text-lg font-medium text-primary">
                            {t("slaughter-day")}
                        </span>
                        <span className="text-[rgba(82,82,82,1)]">
                            {t(`day-${slaughterDay}`)}
                        </span>
                    </div>
                ) : (
                    <div className="flex flex-col items-start gap-2 leading-none">
                        <span className="text-lg font-medium text-[rgba(240,61,67,1)]">
                            {t("select-slaughter-day")}
                        </span>
                    </div>
                )}
                <Chevron />
            </button>
            <span className="text-xl font-medium text-[rgba(23,23,23,1)]">
                {t("select-delivery-day")}
            </span>
            <button
                onClick={() => {
                    setIsChoosingDeliveryDay(true);
                }}
                className="flex items-center gap-4 rounded-lg border border-[rgba(245,245,245,1)] bg-[rgba(250,250,250,1)] p-4 transition hover:opacity-80"
            >
                <DeliveryCar />
                {deliveryDay ? (
                    <div className="flex flex-col items-start gap-2 leading-none">
                        <span className="text-lg font-medium text-primary">
                            {t("delivery-day")}
                        </span>
                        <span className="text-[rgba(82,82,82,1)]">
                            {t(`day-${deliveryDay}`)}
                        </span>
                    </div>
                ) : (
                    <div className="flex flex-col items-start gap-2 leading-none">
                        <span className="text-lg font-medium text-[rgba(240,61,67,1)]">
                            {t("select-delivery-day")}
                        </span>
                    </div>
                )}
                <Chevron />
            </button>

            <SelectionModal
                isOpen={isChoosingSlaughterDay}
                setIsOpen={setIsChoosingSlaughterDay}
                selectionTitle={t("slaughter-selection")}
                selectedDay={slaughterDay}
                setSelectedDay={setSlaughterDay}
                confirmText={t("confirm-day")}
            />
            <SelectionModal
                isOpen={isChoosingDeliveryDay}
                setIsOpen={setIsChoosingDeliveryDay}
                selectionTitle={t("delivery-selection")}
                selectedDay={deliveryDay}
                setSelectedDay={setDeliveryDay}
                confirmText={t("confirm-day")}
            />
        </div>
    );
}
