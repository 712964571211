import React, { useState, useEffect } from "react";
import {Clock} from "../../assets/icons";

const LimitedTimeOffer = ({
  data,
}) => {
  const [remainingDays, setRemainingDays] = useState(0);
  const [remainingHours, setRemainingHours] = useState(0);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [discountPrecentage, setDiscountPrecentage] = useState(0);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    let _remainingDays = 0,
      _remainingHours = 0,
      _remainingMinutes = 0,
      _remainingSeconds = 0,
      _isExpired = false;
    if (
      data?.limited_offer.has_limited_offer &&
      data?.limited_offer.limited_offer_expired_at
    ) {
      const expiredAt = new Date(data?.limited_offer.limited_offer_expired_at);
      const now = new Date();
      const diff = expiredAt.getTime() - now.getTime();
      _isExpired = diff < 0;
      _remainingDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      _remainingHours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      _remainingMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      _remainingSeconds = Math.floor((diff % (1000 * 60)) / 1000);
    }
    let _discountPrecentage = 0;
    if (data?.old_price && data?.price) {
      _discountPrecentage =
        (1 - Number(data?.price) / Number(data?.old_price)) * 100;
    }
    setRemainingDays(_remainingDays);
    setRemainingHours(_remainingHours);
    setRemainingMinutes(_remainingMinutes);
    setRemainingSeconds(_remainingSeconds);
    setDiscountPrecentage(_discountPrecentage);
  }, [data]);

  useEffect(() => {
    if (!data || isExpired || !data.limited_offer.has_limited_offer) return;

    const interval = setInterval(() => {
      if (isExpired) return;
      if (remainingSeconds > 0) {
        setRemainingSeconds(remainingSeconds - 1);
      } else {
        if (remainingMinutes > 0) {
          setRemainingMinutes(remainingMinutes - 1);
          setRemainingSeconds(59);
        } else {
          if (remainingHours > 0) {
            setRemainingHours(remainingHours - 1);
            setRemainingMinutes(59);
          } else {
            if (remainingDays > 0) {
              setRemainingDays(remainingDays - 1);
              setRemainingHours(23);
            }
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [
    remainingSeconds,
    remainingMinutes,
    remainingHours,
    remainingDays,
    data,
    isExpired,
  ]);

  if (!data?.limited_offer.has_limited_offer || isExpired) return null;

  return (
    <div className="relative flex items-center justify-center gap-x-0.5 bg-[rgba(240,61,67,1)] py-1.5 pe-6 ps-2 text-xs font-medium leading-none text-white [clipPath:polygon(100%_0,90%_50%,100%_100%,0%_100%,0_50%,0%_0%)] rtl:[clipPath:polygon(0%_0,10%_50%,0%_100%,100%_100%,100%_50%,100%_0%)]">
      <Clock />
      <span className="pb-1 tabular-nums leading-none">
        {remainingDays < 10 ? `0${remainingDays}` : remainingDays}:
        {remainingHours < 10 ? `0${remainingHours}` : remainingHours}:
        {remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes}:
        {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
      </span>
    </div>
  );
};

export default LimitedTimeOffer;
