import { useTranslation } from "react-i18next";
import SelectCountryCode from "../modals/select-country-code";
import { useState } from "react";
import useUserStore from "../../store/userStore";

export default function ShareApp() {
    const { t } = useTranslation();
    const country_code = useUserStore((state) => state.user?.country_code);
    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);
    const [countryCode, setCountryCode] = useState(country_code || 971);
    const [mobile, setMobile] = useState("");

    return (
        <div className="ms-auto flex max-w-[917px] flex-col gap-10 px-4 ">
            <h2 className="text-center text-[28px] font-medium text-[rgba(23,23,23,1)]">{t("account.favourite")}</h2>
            <div className="flex flex-col gap-6 rounded-xl bg-[rgba(245,245,245,1)] p-4">
                <span className="text-lg font-medium text-[rgba(38,38,38,1)]">{t("account.invite-friends")}</span>
                <div className="flex flex-col items-center rounded-lg bg-[rgba(207,219,211,1)] p-6">
                    <span className="text-[rgba(38,38,38,1)]">{t("account.add-phone")}</span>
                    <div>
                        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_258_203342)">
                                <path
                                    d="M48.7833 56.2719L43.1411 57.4276C50.6417 45.0977 48.4124 37.7504 46.4971 34.5955C46.5896 34.3232 46.7414 34.0894 46.8339 33.8171C47.7968 31.0355 49.8998 21.4327 35.8992 12.3413L35.8398 12.3027L35.0808 13.4717L35.1401 13.5102C48.0729 21.9082 46.3446 30.6742 45.4363 33.2419C43.4594 30.9611 40.7662 30.0432 38.7709 30.9079C37.0081 31.6744 36.204 33.562 36.5272 36.1816C36.9406 39.4414 38.846 40.0139 39.629 40.1069C41.6648 40.3487 44.0942 38.6856 45.6076 36.0953C47.6987 40.2783 47.5195 47.3081 41.8953 56.6186L40.6772 51.0913L39.3153 51.3702L41.0677 59.3219L49.0445 57.688L48.7833 56.2719ZM39.7782 38.7082C38.5254 38.5594 38.0696 37.1832 37.9104 35.9996C37.6396 34.0788 38.1994 32.6973 39.3619 32.2058C40.3892 31.7926 41.6634 32.0384 42.8499 32.8089C43.3839 33.1556 43.9392 33.5992 44.4185 34.1598C44.5586 34.3338 44.6986 34.5079 44.8386 34.6819C43.4439 37.3492 41.2849 38.8556 39.7782 38.7082Z"
                                    fill="#0A4C26"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_258_203342">
                                    <rect width="56.4717" height="56.4717" fill="white" transform="translate(31.5) rotate(32.998)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="flex w-full max-w-[305px] overflow-hidden rounded-lg border border-white bg-white">
                        <div className="flex flex-1 items-center gap-2 px-3 py-3.5">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
                                <path
                                    d="M14.6473 12.2207C14.6473 12.4607 14.594 12.7073 14.4806 12.9473C14.3673 13.1873 14.2207 13.414 14.0273 13.6273C13.7007 13.9873 13.3407 14.2473 12.934 14.414C12.534 14.5807 12.1007 14.6673 11.634 14.6673C10.954 14.6673 10.2273 14.5073 9.46065 14.1807C8.69398 13.854 7.92732 13.414 7.16732 12.8607C6.40065 12.3007 5.67398 11.6807 4.98065 10.994C4.29398 10.3007 3.67398 9.57398 3.12065 8.81398C2.57398 8.05398 2.13398 7.29398 1.81398 6.54065C1.49398 5.78065 1.33398 5.05398 1.33398 4.36065C1.33398 3.90732 1.41398 3.47398 1.57398 3.07398C1.73398 2.66732 1.98732 2.29398 2.34065 1.96065C2.76732 1.54065 3.23398 1.33398 3.72732 1.33398C3.91398 1.33398 4.10065 1.37398 4.26732 1.45398C4.44065 1.53398 4.59398 1.65398 4.71398 1.82732L6.26065 4.00732C6.38065 4.17398 6.46732 4.32732 6.52732 4.47398C6.58732 4.61398 6.62065 4.75398 6.62065 4.88065C6.62065 5.04065 6.57398 5.20065 6.48065 5.35398C6.39398 5.50732 6.26732 5.66732 6.10732 5.82732L5.60065 6.35398C5.52732 6.42732 5.49398 6.51398 5.49398 6.62065C5.49398 6.67398 5.50065 6.72065 5.51398 6.77398C5.53398 6.82732 5.55398 6.86732 5.56732 6.90732C5.68732 7.12732 5.89398 7.41398 6.18732 7.76065C6.48732 8.10732 6.80732 8.46065 7.15398 8.81398C7.51398 9.16732 7.86065 9.49398 8.21398 9.79398C8.56065 10.0873 8.84732 10.2873 9.07398 10.4073C9.10732 10.4207 9.14732 10.4407 9.19398 10.4607C9.24732 10.4807 9.30065 10.4873 9.36065 10.4873C9.47398 10.4873 9.56065 10.4473 9.63398 10.374L10.1407 9.87398C10.3073 9.70732 10.4673 9.58065 10.6207 9.50065C10.774 9.40732 10.9273 9.36065 11.094 9.36065C11.2207 9.36065 11.354 9.38732 11.5007 9.44732C11.6473 9.50732 11.8007 9.59398 11.9673 9.70732L14.174 11.274C14.3473 11.394 14.4673 11.534 14.5406 11.7006C14.6073 11.8673 14.6473 12.034 14.6473 12.2207Z"
                                    stroke="#262626"
                                    strokeMiterlimit="10"
                                />
                            </svg>
                            <input
                                type="text"
                                className="h-3 w-0 flex-1 bg-transparent pb-0.5 text-xs font-light text-[rgba(38,38,38,1)] placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                                placeholder={t("modals.mobile")}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                            />
                            <button className="flex items-center text-sm transition hover:opacity-80" onClick={() => setIsCountryCodeSelecting(true)}>
                                +{countryCode}
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_258_154458)">
                                        <path
                                            d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                                            fill="#525252"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_258_154458">
                                            <rect width="11.5" height="11.5" fill="white" transform="translate(0.5 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                        <button className="bg-primary px-[25px] text-sm text-white transition hover:opacity-80">{t("send")}</button>
                    </div>
                </div>
            </div>
            {isCountryCodeSelecting && (
                <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed">
                    <div className="ReactModal__Content ReactModal__Content--after-open bg-white">
                        <div className="flex flex-col">
                            <div className="mb-4 flex">
                                <button className="ms-auto transition hover:scale-125" onClick={() => setIsCountryCodeSelecting(false)}>
                                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <SelectCountryCode setCountryCode={setCountryCode} countryCode={countryCode.toString()} setIsCountryCodeSelecting={setIsCountryCodeSelecting} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
