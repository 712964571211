import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";


const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();

  const observer = useRef();

  const lastNotificationElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/user/notifications?page=${page}`);
        setNotifications(prevNotifications => {
          return [...new Set([...prevNotifications, ...response.data.data])]
        });
        setHasMore(response.data.data.length > 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [page]);
  
  if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;

  return (
    <div className="mx-auto flex max-w-[852px] flex-col px-4 pb-28 pt-14">
      <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
          {t("navlist.notifications")}
      </h2>
      <div className="w-100">
        {notifications.map((notification, index) => {
          if (notifications.length === index + 1) {
            return (
              <div ref={lastNotificationElementRef} key={index} className="p-4 bg-white shadow rounded-lg mb-4">
                <p className="text-gray-600">{notification.message ?? ''}</p>
                {/* <span className="text-sm text-gray-500">{new Date(notification.date).toLocaleString()}</span> */}
              </div>
            );
          } else {
            return (
              <div key={index} className="p-4 bg-white shadow rounded-lg mb-4">
                <p className="text-gray-600">{notification.message ?? ''}</p>
                {/* <span className="text-sm text-gray-500">{new Date(notification.date).toLocaleString()}</span> */}
              </div>
            );
          }
        })}
        {loading && <div className="text-center py-4">Loading...</div>}
      </div>
    </div>
  );
};

export default Notifications;
