import { useTranslation } from "react-i18next";
import countries from "../../assets/countries.json";
import { cn } from "../../lib/utils";

export default function SelectCountryCode({
    countryCode,
    setCountryCode,
    setIsCountryCodeSelecting,
}: {
    countryCode: string;
    setCountryCode: (code: string) => void;
    setIsCountryCodeSelecting: (isSelecting: boolean) => void;
}) {
    const { t } = useTranslation();
    return (
        <>
            <span className="mb-8 text-2xl font-medium text-foreground">
                {t("modals.select-country-code")}
            </span>
            <div className="scroll flex max-h-[60vh] flex-col gap-y-3 overflow-y-auto pr-[3px]">
                {countries.map((country) => (
                    <button
                        key={country.code + country.name}
                        className={cn(
                            "mb-4 flex items-center justify-between rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] font-light text-[rgba(41,48,50,1)] transition hover:opacity-80 rtl:flex-row-reverse",
                            {
                                "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                    country.code === countryCode,
                            },
                        )}
                        onClick={() => {
                            setCountryCode(country.code);
                            setIsCountryCodeSelecting(false);
                        }}
                    >
                        <img
                            src={`https://flagcdn.com/${country.cca2.toLowerCase()}.svg`}
                            alt="flag"
                            className="mr-2 h-[18px] w-[27px]"
                        />
                        <span>{country.name}</span>
                        <span className="ml-auto">
                            <span className="rtl:hidden">+</span>
                            {country.code}
                            <span className="ltr:hidden">+</span>
                        </span>
                    </button>
                ))}
            </div>
        </>
    );
}
