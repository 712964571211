import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../../../assets/icons";
import useGiftsStore from "../../../store/giftsStore";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function CardContent() {
    const { cart_id } = useParams();
    const { t } = useTranslation();

    const {
        giftRecipientName,
        messageSubject,
        messageContent,
        senderName,
        videoLink,
        images,
        selectDataFromRecipient,

        setGiftRecipientName,
        setMessageSubject,
        setMessageContent,
        setSenderName,
        setVideoLink,
        setImages,
        setSelectDataFromRecipient,
    } = useGiftsStore();
    const [_selectDataFromRecipient, set_SelectDataFromRecipient] = useState(
        selectDataFromRecipient,
    );

    const fileInput = useRef<HTMLInputElement>(null);
    const [imagesUrl, setImagesUrl] = useState<string[]>(
        images.map((file) => URL.createObjectURL(file)),
    );

    const handleImagesUpload = () => {
        if (fileInput.current?.files) {
            const files = Array.from(fileInput.current.files);
            files.push(...images);
            setImages(files);
            const urls = files.map((file) => URL.createObjectURL(file));
            setImagesUrl(urls);
        }
    };

    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const recipientName = formData.get("recipient-name") as string;
        const messageSubject = formData.get("message-subject") as string;
        const messageContent = formData.get("message-content") as string;
        const senderName = formData.get("sender-name") as string;
        const videoLink = formData.get("video-link") as string;
        const _images = Array.from(
            formData.getAll("images") as unknown as FileList,
        );
        _images.push(...images);
        const _selectDataFromRecipient = formData.get("select-data-recipient");
        if (!recipientName || !senderName || !videoLink || !_images)
            return toast.error(t("fill-fields"));

        setGiftRecipientName(recipientName);
        setMessageSubject(messageSubject);
        setMessageContent(messageContent);
        setSenderName(senderName);
        setVideoLink(videoLink);
        setImages(_images);
        setSelectDataFromRecipient(_selectDataFromRecipient === "true");

        navigate("/checkout/gifts/" + cart_id);
    };

    return (
        <div className="pt mx-auto flex max-w-[852px] flex-col px-4 pb-28 pt-14">
            <h2 className="mb-10 text-center text-3xl font-medium text-[rgba(23,23,23,1)] xl:text-[40px]">
                {t("gift-card-content")}
            </h2>
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div className="flex flex-col gap-2">
                    <label
                        htmlFor="recipient-name"
                        className="text-sm text-[rgba(23,23,23,1)]"
                    >
                        {t("gift-recipient-name")}
                    </label>
                    <input
                        name="recipient-name"
                        id="recipient-name"
                        type="text"
                        placeholder={t("name")}
                        defaultValue={giftRecipientName}
                        className="rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:font-light placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label
                        htmlFor="message-subject"
                        className="text-sm text-[rgba(23,23,23,1)]"
                    >
                        {t("message-subject")}
                        <span className="ms-2 text-[rgba(163,163,163,1)]">
                            {t("optional")}
                        </span>
                    </label>
                    <input
                        name="message-subject"
                        id="message-subject"
                        type="text"
                        defaultValue={messageSubject}
                        placeholder={t("subject")}
                        className="rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:font-light placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label
                        htmlFor="message-content"
                        className="text-sm text-[rgba(23,23,23,1)]"
                    >
                        {t("gift-message-content")}
                        <span className="ms-2 text-[rgba(163,163,163,1)]">
                            {t("optional")}
                        </span>
                    </label>
                    <textarea
                        name="message-content"
                        id="message-content"
                        placeholder={t("content")}
                        defaultValue={messageContent}
                        className="min-h-[88px] rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:font-light placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label
                        htmlFor="sender-name"
                        className="text-sm text-[rgba(23,23,23,1)]"
                    >
                        {t("want-write-name")}
                    </label>
                    <input
                        name="sender-name"
                        id="sender-name"
                        defaultValue={senderName}
                        placeholder={t("name")}
                        className="rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:font-light placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label
                        htmlFor="video-link"
                        className="text-sm text-[rgba(23,23,23,1)]"
                    >
                        {t("have-video-link")}
                    </label>
                    <input
                        name="video-link"
                        id="video-link"
                        defaultValue={videoLink}
                        placeholder={t("link")}
                        className="rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:font-light placeholder:text-[rgba(163,163,163,1)] focus:outline-none"
                    />
                </div>

                <div className="rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]">
                    {t("recipient-delivery-communcation")}
                </div>

                <div className="flex items-center justify-between rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]">
                    <span className="text-[rgba(38,38,38,1)]">
                        {t("select-data-recipient")}
                    </span>

                    <label className="inline-flex cursor-pointer items-center">
                        <input
                            name="select-data-recipient"
                            type="checkbox"
                            defaultChecked={selectDataFromRecipient}
                            value={_selectDataFromRecipient.toString()}
                            onChange={() =>
                                set_SelectDataFromRecipient(
                                    !_selectDataFromRecipient,
                                )
                            }
                            className="peer sr-only"
                        />
                        <div className="peer relative h-8 w-14 rounded-full bg-[rgba(212,212,212,1)] after:absolute after:start-[4px] after:top-[3.5px] after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full"></div>
                    </label>
                </div>

                <div className="flex gap-2">
                    <button
                        type="button"
                        onClick={() => fileInput.current?.click()}
                        className="flex h-[82px] w-[120px] items-center justify-center rounded-lg bg-[rgba(209,224,215,1)]"
                    >
                        <PlusIcon />
                    </button>
                    <input
                        name="images"
                        type="file"
                        multiple
                        className="hidden"
                        ref={fileInput}
                        onChange={handleImagesUpload}
                    />

                    {imagesUrl.map((url, index) => (
                        <div
                            key={index}
                            className="relative h-[82px] w-[120px] rounded-lg bg-[rgba(209,224,215,1)]"
                        >
                            <img
                                src={url}
                                alt="uploaded"
                                className="h-full w-full object-cover"
                            />
                        </div>
                    ))}
                </div>

                <div className="mx-auto mt-10 flex w-full max-w-[368px] flex-col gap-4">
                    <button
                        type="submit"
                        className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                    >
                        {t("continue")}
                    </button>
                </div>
            </form>
        </div>
    );
}
