import React from "react";
import Accordion from "../../accordion";
import { useTranslation } from "react-i18next";

const WithoutSection = ({ attribute, cartAttributes, pushAttribute }) => {
    const { i18n, t } = useTranslation();
    //select all values if no value is selected
    if (!cartAttributes[attribute.id])
        attribute.values.map((value) =>
            pushAttribute(attribute.id, value.id, true),
        );
    return (
        <Accordion title={attribute.name} key={attribute.id}>
            <div className="flex flex-col gap-y-1">
                {attribute.values.map((value, idx) => (
                    <div className="contents" key={value.id}>
                        <button
                            className="flex w-full items-center gap-2 py-3.5"
                            onClick={() =>
                                pushAttribute(attribute.id, value.id, true)
                            }
                        >
                            <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.6847 5.08984H7.32102C6.09093 5.08984 5.09375 6.08703 5.09375 7.31712V20.6808C5.09375 21.9108 6.09093 22.908 7.32102 22.908H20.6847C21.9147 22.908 22.9119 21.9108 22.9119 20.6808V7.31712C22.9119 6.08703 21.9147 5.08984 20.6847 5.08984Z"
                                    stroke="#0A4C26"
                                    strokeWidth="1.27273"
                                    strokeLinejoin="round"
                                />
                                {cartAttributes[attribute.id] &&
                                    Array.isArray(
                                        cartAttributes[attribute.id].value_id,
                                    ) &&
                                    cartAttributes[
                                        attribute.id
                                    ].value_id.includes(value.id) && (
                                        <path
                                            d="M18.456 10.2871L12.2196 17.7114L9.54688 14.7417"
                                            stroke="#0A4C26"
                                            strokeWidth="1.27273"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    )}
                            </svg>
                            <span className="text-[rgba(23,23,23,1)]">
                                {value.value}
                            </span>
                        </button>
                        {idx !== attribute.values.length - 1 && (
                            <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                        )}
                    </div>
                ))}
            </div>
        </Accordion>
    );
};

export default WithoutSection;
