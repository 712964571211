import { create } from "zustand";
import { devtools } from "zustand/middleware";

type GiftsStore = {
    address_id: string;
    recipientName: string;
    recipientPhone: string;
    recipientCountryCode: string;
    recipientEmirateId: string;
    giftRecipientName: string;
    messageSubject: string;
    messageContent: string;
    senderName: string;
    videoLink: string;
    images: File[];
    selectDataFromRecipient: boolean;
    deliveryDate: {
        time_from: string;
        time_to: string;
        date: string;
        deliveryDay: string;
    };

    setAddressId: (address_id: string) => void;
    setRecipientName: (recipientName: string) => void;
    setRecipientPhone: (recipientPhone: string) => void;
    setRecipientCountryCode: (recipientCountryCode: string) => void;
    setRecipientEmirateId: (recipientEmirateId: string) => void;
    setGiftRecipientName: (giftRecipientName: string) => void;
    setMessageSubject: (messageSubject: string) => void;
    setMessageContent: (messageContent: string) => void;
    setSenderName: (senderName: string) => void;
    setVideoLink: (videoLink: string) => void;
    setImages: (images: File[]) => void;
    setSelectDataFromRecipient: (selectDataFromRecipient: boolean) => void;
    setDeliveryDate: (deliveryDate: {
        time_from: string;
        time_to: string;
        date: string;
        deliveryDay: string;
    }) => void;
};

const useGiftsStore = create<GiftsStore>()(
    devtools(
        (set) => ({
            address_id: "",
            recipientName: "",
            recipientPhone: "",
            recipientCountryCode: "",
            recipientEmirateId: "",
            giftRecipientName: "",
            messageSubject: "",
            messageContent: "",
            senderName: "",
            videoLink: "",
            images: [],
            selectDataFromRecipient: false,
            deliveryDate: {
                time_from: "",
                time_to: "",
                date: "",
                deliveryDay: "",
            },

            setAddressId: (address_id) => set({ address_id }),
            setRecipientName: (recipientName) => set({ recipientName }),
            setRecipientPhone: (recipientPhone) => set({ recipientPhone }),
            setRecipientCountryCode: (recipientCountryCode) =>
                set({ recipientCountryCode }),
            setRecipientEmirateId: (recipientEmirateId) =>
                set({ recipientEmirateId }),
            setGiftRecipientName: (giftRecipientName) =>
                set({ giftRecipientName }),
            setMessageSubject: (messageSubject) => set({ messageSubject }),
            setMessageContent: (messageContent) => set({ messageContent }),
            setSenderName: (senderName) => set({ senderName }),
            setVideoLink: (videoLink) => set({ videoLink }),
            setImages: (images) => set({ images }),
            setSelectDataFromRecipient: (selectDataFromRecipient) =>
                set({ selectDataFromRecipient }),
            setDeliveryDate: (deliveryDate) => set({ deliveryDate }),
        }),
        {
            enabled: true,
            name: "gifts-store",
        },
    ),
);

export default useGiftsStore;
