import axios from "axios";
import Product from "../types/product";

export async function getFavourite() {
    try {
        const response = await axios.get(`/user/favourites/products`);
        const data = response.data;

        return data.data as { id: number; products: Product }[];
    } catch (error) {
        console.error(error);
    }
}
