import { useEffect, useRef } from "react";

export default function CustomModal({
    isOpen,
    children,
    setIsOpen,
}: {
    isOpen: boolean;
    children: React.ReactNode;
    setIsOpen: (isOpen: boolean) => void;
}) {
    const modalRef = useRef<HTMLDivElement>(null);

    const closeModal = (e: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", closeModal);
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    if (!isOpen) return null;

    return (
        <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed inset-0">
            <div
                className="ReactModal__Content ReactModal__Content--after-open mx-auto !max-w-[1076px] bg-white"
                ref={modalRef}
            >
                {children}
            </div>
        </div>
    );
}
