import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getShop } from "../actions/getShop";
import { cn } from "../lib/utils";
import ProductsSection from "../components/home/products-section";
import Product from "../types/product";
import LoadingScreen from "../components/loading-screen";

export default function Shop() {
    const { shopId } = useParams();
    const { t } = useTranslation();
    const { data, isLoading } = useQuery({
        queryKey: ["global", "shop", { shopId }],
        queryFn: () =>
            getShop({
                shopId: shopId || "",
            }),
    });

    const [activeTab, setActiveTab] = useState<string>("all");

    useEffect(() => {
        if (data) {
            setFilteredProducts(data.products);
        }
    }, [data]);

    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

    if (isLoading) return <LoadingScreen />;
    if (!data) return null;
    return (
        <div className="container flex flex-col gap-y-10 pb-28 pt-14 xl:gap-y-20">
            <h2 className="text-center text-3xl font-medium text-[rgba(23,23,23,1)] xl:text-[40px]">{data.name}</h2>
            <div className="flex flex-wrap items-center gap-6">
                <div className="rounded-[20px] bg-white shadow-[0px_5.48px_10.96px_0px_rgba(0,0,0,0.07)]">
                    <img src={data.image} alt={data.name} className="size-[148px] rounded-[20px] object-contain" />
                </div>
                <div className="flex flex-col gap-2">
                    <span className="text-[32px] font-medium text-[rgba(23,23,23,1)]">{data.name}</span>
                    <div className="flex items-center gap-x-0.5">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.3348 8.47079L12.2298 7.84374L10.3899 3.92457C10.2524 3.63191 9.77227 3.63191 9.63484 3.92457L7.79544 7.84374L3.69044 8.47079C3.35327 8.52253 3.21862 8.93258 3.45509 9.17461L6.4373 12.2314L5.73237 16.5528C5.67617 16.8961 6.04283 17.1537 6.34606 16.9851L10.0126 14.9588L13.6792 16.9857C13.9796 17.1526 14.3496 16.9 14.2929 16.5534L13.588 12.2319L16.5702 9.17517C16.8066 8.93258 16.6714 8.52253 16.3348 8.47079Z"
                                fill="#FFC107"
                            />
                        </svg>
                        <span>{t("rate")}</span>
                        <span>{data.rating ? data.rating : 0}</span>
                    </div>
                    <a href="/" className="w-fit text-[28px] font-light text-primary underline transition hover:opacity-80">
                        {t("review")}
                    </a>
                </div>
            </div>
            {data.menus.length > 0 && (
                <div className="flex flex-wrap items-center gap-y-4 border-y border-[rgba(229,229,229,1)] bg-white px-7 pt-[18px]">
                    <button
                        className={cn("border-b-4 border-transparent px-[30px] pb-6 text-xl font-light text-[rgba(38,38,38,1)]", {
                            "border-primary font-medium text-primary": "all" === activeTab,
                        })}
                        onClick={() => {
                            setActiveTab("all");
                            setFilteredProducts(data.products);
                        }}
                    >
                        {t("all")}
                    </button>
                    {data.menus.map((tab) => (
                        <button
                            key={tab.id}
                            className={cn("border-b-4 border-transparent px-[30px] pb-6 text-xl font-light text-[rgba(38,38,38,1)]", {
                                "border-primary font-medium text-primary": tab.name === activeTab,
                            })}
                            onClick={() => {
                                setActiveTab(tab.name);
                                setFilteredProducts(data.products.filter((product) => product.menu_id === tab.id));
                            }}
                        >
                            {tab.name}
                        </button>
                    ))}
                </div>
            )}
            {filteredProducts.length > 0 && <ProductsSection products={filteredProducts} title={""} />}
        </div>
    );
}
