import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "../components/loading-screen";
import axios from "axios";

export default function TermsConditions() {
    const { t } = useTranslation();
    const { data, isFetching } = useQuery({
        queryKey: ["global", "terms"],
        queryFn: async () => {
            const response = await axios.get("/terms");
            return response.data.data.content;
        },
    });

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;
    return (
        <div className="mx-auto flex max-w-[885px] flex-col items-center gap-10 px-4 py-[60px]">
            <h2 className="text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {t("terms-and-conditions")}
            </h2>
            <div
                className="reset-tw"
                dangerouslySetInnerHTML={{ __html: data }}
            />
        </div>
    );
}
