import { useTranslation } from "react-i18next";
import Modal from "../custom-modal";
import { CloseIcon } from "../../assets/icons";

export default function SelectionModal({
    isOpen,
    setIsOpen,
    selectionTitle,
    selectedDay,
    setSelectedDay,
    optionsCount = 4,
    confirmText,
}: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectionTitle: string;
    selectedDay: number | null;
    setSelectedDay: (day: number | null) => void;
    optionsCount?: number;
    confirmText: string;
}) {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex flex-col gap-10 sm:-m-4">
                <div className="flex items-center justify-between">
                    <span className="text-2xl font-medium text-[rgba(15,23,42,1)]">
                        {selectionTitle}
                    </span>
                    <button
                        className="transition hover:scale-110"
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                    {Array.from(
                        { length: optionsCount },
                        (_, index) => index + 1,
                    ).map((day) => (
                        <button
                            key={day}
                            className={`rounded-xl border border-[rgba(229,229,229,1)] py-12 text-lg text-[rgba(64,64,64,1)] ${selectedDay === day ? "border-primary text-primary" : ""}`}
                            onClick={() => {
                                setSelectedDay(day);
                            }}
                        >
                            {t(`day-${day}`)}
                        </button>
                    ))}
                </div>
                {selectedDay && (
                    <div className="flex justify-center">
                        <button
                            onClick={() => setIsOpen(false)}
                            className="flex w-full max-w-[302px] items-center justify-center rounded-lg bg-primary px-6 py-[18px] text-white"
                        >
                            {confirmText}
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
}
