import axios from "axios";
import Product from "../types/product";

export async function getSearchProducts({ q }: { q: string }) {
    try {
        const response = await axios.post(`/products`, {
            q,
        });
        const data = response.data;
        return data.data as Product[];
    } catch (error) {
        console.error(error);
    }
}
