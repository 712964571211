import axios from "axios";
import { Branch, Emirate } from "../store/userStore";

export async function getAddresses() {
    try {
        const response = await axios.get("/user/addresses?is_gift=2");
        const data = response.data;
        return data.data as Address[];
    } catch (error) {
        console.error(error);
    }
}

export interface Address {
    id: string;
    name: string;
    receiverName: string | null;
    address: string;
    mobile: string | null;
    streetName: string;
    apartment_num: string;
    lat: number;
    lng: number;
    buildingNumber: string;
    addressType: "home" | "work" | "other";
    notes: string | null;
    isGift: boolean;
    isDefault: boolean;
    region: {
        id: number;
        name: string;
        branch: Branch;
    };
    emirate: Emirate;
    branch: Branch;
    street_name: string;
    building_number: string;
}
