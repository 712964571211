import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Product from "../types/product";
import { Shop } from "../types/shop";
import LoadingScreen from "../components/loading-screen";
import ProductCard from "../components/home/product-card";
import ShopCard from "../components/home/shop-card";

export default function Offers() {
    const { t } = useTranslation();
    const { data, isFetching } = useQuery({
        queryKey: ["global", "offers"],
        queryFn: async () => {
            const response = await axios.get("/offers");
            return response.data.data as {
                products: Product[];
                shops: Shop[];
            };
        },
    });

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;
    return (
        <div className="container pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">{t("offers")}</h2>
            <h3 className="mb-6 text-2xl font-medium text-[rgba(23,23,23,1)]">{t("all-products")}</h3>
            <div className="mb-8 grid gap-6 sm:grid-cols-3 xl:grid-cols-6">
                {data.products.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
            <h3 className="mb-6 text-2xl font-medium text-[rgba(23,23,23,1)]">{t("all-shops")}</h3>
            <div className="mb-8 grid gap-6 sm:grid-cols-2 xl:grid-cols-5">
                {data.shops.map((shop) => (
                    <ShopCard key={shop.id} shop={shop} />
                ))}
            </div>
        </div>
    );
}
