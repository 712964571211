import { useTranslation } from "react-i18next";
import useModalsStore from "../../store/modalsStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Logo from "../../assets/logo";
import LoadingScreen from "../loading-screen";
import { getAddresses } from "../../actions/getAddresses";
import { useEffect, useState } from "react";
import { Delete, Edit } from "../../assets/icons";
interface Address {
    id: string;
    name?: string;
    region?: { name: string };
    emirate?: { name: string };
    branch?: { name: string };
    building_number?: string;
    apartment_num?: string;
}

export default function Addresses() {
    const { t } = useTranslation();
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const navigate = useNavigate();
    const { data, isFetching, refetch } = useQuery({
        queryKey: ["global", "addresses"],
        queryFn: getAddresses,
    });
    const setModalContent = useModalsStore((state) => state.setModalContent);
    const setModalOpen = useModalsStore((state) => state.setModalOpen);
    const modalContent = useModalsStore((state) => state.modalContent);
    const [filteredAddress, setFilteredAddress] = useState<Address[]>([]);

    useEffect(() => {
        if (data) {
            var fData = data?.filter(
                (item) => item.region && item.emirate && item.branch,
            );
            setFilteredAddress(fData);
        }
    }, [data]);

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;

    async function deleteAddress(id: string) {
        setModalContent({
            ...modalContent,
            loading: true,
        });
        try {
            const formData = new FormData();
            formData.append("address_id", id.toString());
            await axios.post("/user/address/delete", formData);
            await refetch();
        } catch (error) {
            console.error(error);
        }
        setModalContent({
            ...modalContent,
            loading: false,
        });
        setModalOpen(false);
    }

    return (
        <div className="container mx-auto flex max-w-[852px] flex-col px-4 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {t("saved-addresses")}
            </h2>
            <div className="flex flex-col gap-4">
                {filteredAddress.map((item) => (
                    <div
                        key={item.id}
                        className="flex flex-col rounded-lg border border-[rgba(229,229,229,1)] p-4"
                    >
                        <div className="flex w-full items-center justify-between gap-2">
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                    stroke="#292D32"
                                    strokeWidth="1.625"
                                />
                                <path
                                    d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                    stroke="#292D32"
                                    strokeWidth="1.625"
                                />
                            </svg>
                            <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                                {t("deliver-to")}
                            </span>
                            <div className="ms-auto flex gap-2">
                                <button
                                    onClick={() => {
                                        navigate(
                                            "/account/add-new-address?redirectTo=/account/addresses",
                                            {
                                                state: {
                                                    editMode: true,
                                                    addressObj: item,
                                                },
                                            },
                                        );
                                    }}
                                >
                                    <Edit width={20} height={20} />
                                </button>
                                <button
                                    onClick={() => {
                                        setModalContent({
                                            title: (
                                                <>
                                                    {t("do-you-want")}{" "}
                                                    <span className="font-semibold">
                                                        {t("delete-address")}
                                                    </span>{" "}
                                                    {t("?")}
                                                </>
                                            ),
                                            confirmText: t("yes"),
                                            cancelText: t("no"),
                                            onConfirm: () =>
                                                deleteAddress(item.id),
                                            onCancel: () => {
                                                setModalOpen(false);
                                            },
                                            loading: false,
                                            icon: (
                                                <Logo className="size-[63px]" />
                                            ),
                                        });
                                        setModalOpen(true);
                                    }}
                                >
                                    <Delete />
                                </button>
                            </div>
                        </div>
                        <span>
                            <p>
                                {item?.name ? `${item.name} - ` : ""}
                                {item?.emirate?.name
                                    ? `${item.emirate.name} - `
                                    : ""}
                                {item?.region?.name
                                    ? `${item.region.name}`
                                    : ""}
                            </p>
                            <p>
                                {item?.building_number
                                    ? `${t("Building Number")} ${item.building_number}, `
                                    : ""}
                                {item?.apartment_num
                                    ? `${t("Apartment Number")} ${item.apartment_num}`
                                    : ""}
                            </p>
                        </span>
                    </div>
                ))}
            </div>
            <div className="mx-auto mt-20 flex w-full max-w-[368px] flex-col gap-4">
                <button
                    onClick={() => {
                        // navigate(
                        //     "/account/add-new-address?redirectTo=/account/addresses",
                        // );
                        setMapModalOpen(true);
                    }}
                    className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                >
                    {t("add-new-address")}
                </button>{" "}
            </div>
        </div>
    );
}
