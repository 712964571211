import React, { useState } from "react";
import useModalsStore from "../../store/modalsStore";
import Cookies from "js-cookie";
import axios from "axios";

export default function AddToFavouriteButton({
    in_favourite,
    productId,
}: {
    in_favourite: boolean;
    productId: number;
}) {
    const setLoginModalOpen = useModalsStore(
        (state) => state.setLoginModalOpen,
    );
    const token = Cookies.get("token");
    const [inFavourite, setInFavourite] = useState(in_favourite);
    const addToFavourite = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        e.preventDefault();
        if (!token) {
            setLoginModalOpen(true);
            return;
        }
        if (inFavourite) {
            removeFromFav();
            setInFavourite(false);
        } else {
            addToFav();
            setInFavourite(true);
        }
    };
    const addToFav = async () => {
        try {
            const formData = new FormData();
            formData.append("product_id", String(productId));
            await axios.post(`/user/favourites/add/product`, formData);
        } catch (error) {
            console.log(error);
        }
    };
    const removeFromFav = async () => {
        try {
            const formData = new FormData();
            formData.append("product_id", String(productId));
            await axios.post(`/user/favourites/delete/product`, formData);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <button
            className="rounded-full bg-white p-1 shadow-[0px_2.71px_5.41px_0px_rgba(26,26,26,0.16)] transition hover:scale-125"
            onClick={addToFavourite}
        >
            <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.2556 17.5273C14.2648 19.2708 12.2282 20.398 11.9816 20.5307C11.735 20.398 9.69838 19.2708 7.70754 17.5273C5.62721 15.7054 3.78194 13.3711 3.78174 10.914C3.78311 9.79048 4.23001 8.7134 5.02444 7.91897C5.81887 7.12454 6.89595 6.67764 8.01943 6.67628C9.45817 6.67639 10.6842 7.29174 11.4408 8.29936L11.9816 9.01963L12.5224 8.29936C13.2789 7.29174 14.505 6.67639 15.9437 6.67628C17.0672 6.67764 18.1443 7.12454 18.9387 7.91897L19.4085 7.44916L18.9387 7.91897C19.7333 8.71349 20.1802 9.79072 20.1814 10.9143C20.1811 13.3714 18.3359 15.7055 16.2556 17.5273Z"
                    stroke={inFavourite ? "rgba(240, 61, 67, 1)" : "#648C74"}
                    fill={inFavourite ? "rgba(240, 61, 67, 1)" : ""}
                    strokeWidth="1.35255"
                />
            </svg>
        </button>
    );
}
