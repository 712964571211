import React from "react";
import Accordion from "../../accordion"; // Ensure the import path is correct
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import AttributeCard from "../../attribute-card"; // Ensure the import path is correct
import { cn } from "../../../lib/utils";
import { useTranslation } from "react-i18next";

const PackagingSection = ({
    attribute,
    pushAttribute,
    cartAttributes,
    data,
}) => {
    const { i18n, t } = useTranslation();
    return (
        <Accordion title={attribute.name} key={attribute.id}>
            <Swiper
                spaceBetween={36}
                slidesPerView={4.2}
                dir={i18n.dir.toString()}
                key={i18n.language + "hero"}
            >
                {attribute.values.map((value, idx) => (
                    <SwiperSlide key={value.id} className="!h-auto">
                        <button
                            className="contents"
                            onClick={() =>
                                pushAttribute(attribute.id, value.id)
                            }
                        >
                            <AttributeCard
                                image={value.image}
                                subTitle={value.value}
                                subSubTitle={value.description || ""}
                                className={cn({
                                    "border-primary":
                                        cartAttributes[attribute.id] &&
                                        cartAttributes[attribute.id].value_id ==
                                            value.id,
                                })}
                                video={value.vidro}
                                title={data.department.name}
                            />
                        </button>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Accordion>
    );
};

export default PackagingSection;
