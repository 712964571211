import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import ProductCard from "../home/product-card";
import { getFavourite } from "../../actions/getFavourite";
import LoadingScreen from "../loading-screen";

export default function Favourite() {
    const { t } = useTranslation();
    const { data, isFetching } = useQuery({
        queryKey: ["global", "favourite"],
        queryFn: getFavourite,
    });

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;

    return (
        <div className="ms-auto max-w-[917px] px-4">
            <h2 className="mb-10 text-center text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {t("account.favourite")}
            </h2>
            <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
                {data.map((fav) =>
                    fav.products ? (
                        <ProductCard key={fav.id} product={fav.products} />
                    ) : null,
                )}
            </div>
        </div>
    );
}
