import { useTranslation } from "react-i18next";
import Logo from "../../assets/logo";
import { useState } from "react";
import LoadingSpinner from "../loading-spinner";
import { RESET_PASSWORD_URL } from "../../lib/urls";
import { md5Encrypt } from "../../lib/utils";
import { toast } from "react-toastify";
import axios from "axios";
import useModalsStore from "../../store/modalsStore";

export default function ForgotPassword({
    mobile,
    forget_token,
}: {
    mobile: string;
    forget_token: string;
}) {
    const { t } = useTranslation();

    const { setOtpModalOpen } = useModalsStore();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

    const [error, setError] = useState<1 | 2 | 3 | null>(null);
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async () => {
        setLoading(true);
        setError(null);
        if (!password) {
            setError(1);
            return;
        }
        if (!confirmPassword) {
            setError(2);
            return;
        }
        if (password !== confirmPassword) {
            setError(3);
            return;
        }
        const formData = new FormData();
        formData.append("mobile", mobile);
        formData.append("new_password", password);
        formData.append("forget_token", forget_token);
        try {
            await axios.post("/user/forget_password/reset_password", formData, {
                headers: {
                    "otp-token": md5Encrypt(mobile),
                },
            });
            toast.success(t("messages.password-reset-success"));
            setOtpModalOpen(false);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <div className="flex flex-col">
            <div className="mb-8 flex justify-center">
                <Logo />
            </div>
            <span className="mb-4 block text-center text-2xl font-medium text-[rgba(15,23,42,1)]">
                {t("modals.login.reset-password")}
            </span>
            <span className="mb-8 block text-center text-lg text-[rgba(115,115,115,1)]">
                {t("modals.login.reset-password-msg")}
            </span>

            <div className="mb-4 w-full">
                <div className="flex items-center gap-x-3 rounded-lg border px-4 py-[18px]">
                    <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_258_154462)">
                            <path
                                d="M15.1437 8.24037C14.7032 7.79994 14.1175 7.5575 13.4946 7.5575H13.3259V5.23944C13.3259 2.87159 11.3996 0.945312 9.03189 0.945312H8.87313C6.50542 0.945312 4.579 2.87159 4.579 5.23944V7.55736H4.41025C3.78742 7.55736 3.20172 7.79994 2.76114 8.24037C2.3207 8.68095 2.07812 9.26665 2.07812 9.88962V15.7188C2.07812 16.3418 2.3207 16.9275 2.76114 17.3678C3.20158 17.8084 3.78728 18.0509 4.41025 18.0509H13.4946C14.1176 18.0509 14.7033 17.8084 15.1439 17.3678C15.5843 16.9273 15.8269 16.3416 15.8269 15.7188V9.88962C15.8269 9.2668 15.5843 8.68109 15.1437 8.24037ZM5.704 5.23944C5.704 3.49203 7.12558 2.07031 8.87313 2.07031H9.03189C10.7793 2.07031 12.2009 3.49203 12.2009 5.23944V7.55736H5.704V5.23944ZM14.7019 15.7188C14.7019 16.0365 14.5729 16.3475 14.3484 16.5723C14.1203 16.8004 13.8171 16.9259 13.4946 16.9259H4.41025C4.0878 16.9259 3.78475 16.8004 3.55652 16.5721C3.32856 16.3443 3.20298 16.0413 3.20298 15.7188V9.88962C3.20298 9.56717 3.32856 9.26384 3.55652 9.03589C3.78461 8.80794 4.08766 8.68236 4.41011 8.68236H13.4945C13.8169 8.68236 14.1201 8.80794 14.3481 9.03575C14.5762 9.26398 14.7017 9.56717 14.7017 9.88962L14.7019 15.7188Z"
                                fill="#525252"
                            />
                            <path
                                d="M8.95312 11.25C8.64248 11.25 8.39062 11.5019 8.39062 11.8125V13.7942C8.39062 14.1048 8.64248 14.3567 8.95312 14.3567C9.26377 14.3567 9.51562 14.1048 9.51562 13.7942V11.8125C9.51562 11.5019 9.26377 11.25 8.95312 11.25Z"
                                fill="#525252"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_258_154462">
                                <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>

                    <input
                        type={visiblePassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t("modals.password")}
                        className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                    />
                    <button
                        className="transition hover:opacity-80"
                        onClick={() => setVisiblePassword(!visiblePassword)}
                    >
                        <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.6849 9.50141C11.6849 10.9864 10.4849 12.1864 8.99994 12.1864C7.51494 12.1864 6.31494 10.9864 6.31494 9.50141C6.31494 8.01641 7.51494 6.81641 8.99994 6.81641C10.4849 6.81641 11.6849 8.01641 11.6849 9.50141Z"
                                stroke="#525252"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.99988 15.7016C11.6474 15.7016 14.1149 14.1416 15.8324 11.4416C16.5074 10.3841 16.5074 8.60656 15.8324 7.54906C14.1149 4.84906 11.6474 3.28906 8.99988 3.28906C6.35238 3.28906 3.88488 4.84906 2.16738 7.54906C1.49238 8.60656 1.49238 10.3841 2.16738 11.4416C3.88488 14.1416 6.35238 15.7016 8.99988 15.7016Z"
                                stroke="#525252"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
                {error === 1 && (
                    <p className="font-light text-red-500">
                        {t("modals.password")}{" "}
                        {t("modals.errors.is-required-h")}
                    </p>
                )}
            </div>
            <div className="mb-6 w-full">
                <div className="flex items-center gap-x-3 rounded-lg border px-4 py-[18px]">
                    <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_258_154462)">
                            <path
                                d="M15.1437 8.24037C14.7032 7.79994 14.1175 7.5575 13.4946 7.5575H13.3259V5.23944C13.3259 2.87159 11.3996 0.945312 9.03189 0.945312H8.87313C6.50542 0.945312 4.579 2.87159 4.579 5.23944V7.55736H4.41025C3.78742 7.55736 3.20172 7.79994 2.76114 8.24037C2.3207 8.68095 2.07812 9.26665 2.07812 9.88962V15.7188C2.07812 16.3418 2.3207 16.9275 2.76114 17.3678C3.20158 17.8084 3.78728 18.0509 4.41025 18.0509H13.4946C14.1176 18.0509 14.7033 17.8084 15.1439 17.3678C15.5843 16.9273 15.8269 16.3416 15.8269 15.7188V9.88962C15.8269 9.2668 15.5843 8.68109 15.1437 8.24037ZM5.704 5.23944C5.704 3.49203 7.12558 2.07031 8.87313 2.07031H9.03189C10.7793 2.07031 12.2009 3.49203 12.2009 5.23944V7.55736H5.704V5.23944ZM14.7019 15.7188C14.7019 16.0365 14.5729 16.3475 14.3484 16.5723C14.1203 16.8004 13.8171 16.9259 13.4946 16.9259H4.41025C4.0878 16.9259 3.78475 16.8004 3.55652 16.5721C3.32856 16.3443 3.20298 16.0413 3.20298 15.7188V9.88962C3.20298 9.56717 3.32856 9.26384 3.55652 9.03589C3.78461 8.80794 4.08766 8.68236 4.41011 8.68236H13.4945C13.8169 8.68236 14.1201 8.80794 14.3481 9.03575C14.5762 9.26398 14.7017 9.56717 14.7017 9.88962L14.7019 15.7188Z"
                                fill="#525252"
                            />
                            <path
                                d="M8.95312 11.25C8.64248 11.25 8.39062 11.5019 8.39062 11.8125V13.7942C8.39062 14.1048 8.64248 14.3567 8.95312 14.3567C9.26377 14.3567 9.51562 14.1048 9.51562 13.7942V11.8125C9.51562 11.5019 9.26377 11.25 8.95312 11.25Z"
                                fill="#525252"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_258_154462">
                                <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>

                    <input
                        type={visibleConfirmPassword ? "text" : "password"}
                        name="confirm_password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder={t("modals.confirm-password")}
                        className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                    />
                    <button
                        className="transition hover:opacity-80"
                        onClick={() =>
                            setVisibleConfirmPassword(!visibleConfirmPassword)
                        }
                    >
                        <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.6849 9.50141C11.6849 10.9864 10.4849 12.1864 8.99994 12.1864C7.51494 12.1864 6.31494 10.9864 6.31494 9.50141C6.31494 8.01641 7.51494 6.81641 8.99994 6.81641C10.4849 6.81641 11.6849 8.01641 11.6849 9.50141Z"
                                stroke="#525252"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.99988 15.7016C11.6474 15.7016 14.1149 14.1416 15.8324 11.4416C16.5074 10.3841 16.5074 8.60656 15.8324 7.54906C14.1149 4.84906 11.6474 3.28906 8.99988 3.28906C6.35238 3.28906 3.88488 4.84906 2.16738 7.54906C1.49238 8.60656 1.49238 10.3841 2.16738 11.4416C3.88488 14.1416 6.35238 15.7016 8.99988 15.7016Z"
                                stroke="#525252"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
                {error === 2 && (
                    <p className="font-light text-red-500">
                        {t("modals.confirm-password")}{" "}
                        {t("modals.errors.is-required")}
                    </p>
                )}
            </div>

            {error === 3 && (
                <p className="font-light text-red-500">
                    {t("modals.errors.confirm-password")}
                </p>
            )}
            <button
                onClick={handleResetPassword}
                className="flex w-full items-center justify-center rounded-lg bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
            >
                {loading ? <LoadingSpinner /> : t("modals.send-btn")}
            </button>
        </div>
    );
}
