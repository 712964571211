import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getProduct } from "../actions/getProduct";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Accordion from "../components/accordion";
import LoadingScreen from "../components/loading-screen";
import TopSection from "../components/product/TopSection";
import SubProductsSection from "../components/product/SubProductsSection";
import Attributes from "../components/product/Attributes";
import BottomSection from "../components/product/BottomSection";

//sub sections

export default function Product() {
    const { productId } = useParams();
    const { i18n, t } = useTranslation();

    const { data, isLoading } = useQuery({
        queryKey: ["global", "product", { productId }],
        queryFn: () =>
            getProduct({
                productId: productId || "",
            }),
    });

    const [subProductID, setSubProductID] = useState(0);
    const [cartAttributes, setCartAttributes] = useState([]);

    const [wantCook, setWantCook] = useState(false);

    if (isLoading) return <LoadingScreen />;
    if (!data) return null;

    return (
        <>
            <div className="mx-auto flex max-w-[852px] flex-col px-4 pt-14">
                {/* title,description and image */}
                <TopSection data={data} />
                <div className="flex flex-col gap-y-4">
                    {data.sub_product && data.sub_product?.length > 0 && (
                        <SubProductsSection
                            data={data}
                            setSubProductID={setSubProductID}
                            subProductID={subProductID}
                        />
                    )}

                    <Attributes
                        data={data}
                        cartAttributes={cartAttributes}
                        setCartAttributes={setCartAttributes}
                    />
                </div>
            </div>

            {/* quantity, price and add to cart  */}
            <BottomSection
                data={data}
                cartAttributes={cartAttributes}
                subProductID={subProductID}
            />
        </>
    );
}
