import axios from "axios";
import { Cart } from "../types/cart";

export async function getCart() {
    try {
        const response = await axios.get("/cart");
        const data = response.data;
        return data.data as Cart;
    } catch (error) {
        console.error(error);
    } 
}
