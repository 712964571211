import { useEffect, useState } from "react";
import HeaderLogo from "../../assets/header-logo";
import { cn } from "../../lib/utils";
import NavList from "./nav-list";
import SearchBox from "./search-box";
import { TopHeader } from "./top-header";
import { useLocation } from "react-router-dom";

export default function Header() {
    const [toggle, setToggle] = useState(false);
    const location = useLocation();
    useEffect(() => {
        setToggle(false);
    }, [location.pathname]);
    return (
        <>
            <TopHeader />
            <div className="sticky left-0 top-0 z-[100] flex items-center gap-x-[45px] bg-white px-4 py-4 shadow-[0px_4px_16px_0px_rgba(2,53,29,0.06)] xl:px-20">
                <a href="/">
                    <HeaderLogo />
                </a>
                <div
                    className={cn(
                        "fixed left-0 top-0 flex min-h-screen w-full -translate-x-full flex-col gap-y-5 bg-white p-4 transition ease-in-out xl:contents",
                        {
                            "-translate-x-0": toggle,
                        },
                    )}
                >
                    <button
                        className="ms-auto xl:hidden"
                        onClick={() => setToggle(false)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-10 w-10"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                    <SearchBox />
                    <NavList />
                </div>
                <button
                    className="ms-auto xl:hidden"
                    onClick={() => setToggle(true)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-12 w-12"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                    </svg>
                </button>
            </div>
        </>
    );
}
