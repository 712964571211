import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { CouponType } from "../components/cart/use-coupon";
import { DeliveryPoint } from "../components/checkout/checkout-sacrifices/choose-delivery-point";

type SlaughterStore = {
    points: string;
    balance: string;
    notes: string;
    coupon: CouponType | null;
    driverTip: string;
    paymentMethod: string;
    deliveryPrice: string;
    address_id: string;
    slaughterDay: number | null;
    deliveryDay: number | null;
    deliveryPoint: DeliveryPoint | null;
    setSlaughterDay: (slaughterDay: number | null) => void;
    setDeliveryDay: (deliveryDay: number | null) => void;
    setPoints: (points: string) => void;
    setBalance: (balance: string) => void;
    setNotes: (notes: string) => void;
    setCoupon: (coupon: CouponType | null) => void;
    setDriverTip: (driverTip: string) => void;
    setPaymentMethod: (paymentMethod: string) => void;
    setDeliveryPrice: (deliveryPrice: string) => void;
    setAddressId: (address_id: string) => void;
    setDeliveryPoint: (deliveryPoint: DeliveryPoint | null) => void;
};

const useSlaughterStore = create<SlaughterStore>()(
    devtools(
        (set) => ({
            points: "",
            balance: "",
            notes: "",
            coupon: null,
            paymentMethod: "",
            driverTip: "",
            deliveryPrice: "",
            address_id: "",
            slaughterDay: null,
            deliveryDay: null,
            deliveryPoint: null,
            setPoints: (points) => set({ points }),
            setBalance: (balance) => set({ balance }),
            setNotes: (notes) => set({ notes }),
            setCoupon: (coupon) => set({ coupon }),
            setDriverTip: (driverTip) => set({ driverTip }),
            setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
            setDeliveryPrice: (deliveryPrice) => set({ deliveryPrice }),
            setAddressId: (address_id) => set({ address_id }),
            setSlaughterDay: (slaughterDay) => set({ slaughterDay }),
            setDeliveryDay: (deliveryDay) => set({ deliveryDay }),
            setDeliveryPoint: (deliveryPoint) => set({ deliveryPoint }),
        }),
        {
            enabled: true,
            name: "slaughter-store",
        },
    ),
);

export default useSlaughterStore;
