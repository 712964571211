import { useState } from "react";
import { cn } from "../lib/utils";

export default function Accordion({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <div>
            <button
                className={cn(
                    "mb-1.5 flex w-full items-center justify-between rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px]",
                    {
                        "border-primary": isOpen,
                    },
                )}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="font-light text-[rgba(38,38,38,1)]">
                    {title}
                </span>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={cn("rotate-180 transition", {
                        "rotate-0": isOpen,
                    })}
                >
                    <path
                        d="M19.9201 15.0487L13.4001 8.52867C12.6301 7.75867 11.3701 7.75867 10.6001 8.52867L4.08008 15.0487"
                        stroke="#292D32"
                        strokeWidth="2.25"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            {isOpen && (
                <div className="rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px]">
                    {children}
                </div>
            )}
        </div>
    );
}
