import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/user";

export default function RetiredMilitary() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState<Language>(
        i18n.language as Language,
    );
    const handleUpdate = () => {
        i18n.changeLanguage(language);
        window.location.reload();
    };
    return (
        <div className="ms-auto max-w-[917px] px-4">
            <h2 className="mb-10 text-center text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {t("account.retired-military-association")}
            </h2>
            <div>
                <span className="mb-2 block text-xl font-medium text-[rgba(38,38,38,1)]">
                    {t("account.enter-membership-number")}
                </span>
                <input
                    type="text"
                    className="block w-full rounded-lg border border-[rgba(229,229,229,1)] px-4 py-[18px] text-[rgba(64,64,64,1)] outline-none placeholder:text-[rgba(212,212,212,1)]"
                    placeholder={t("account.enter-membership-code")}
                />
                <div className="mx-auto mt-10 flex w-full max-w-[368px] flex-col gap-4">
                    <button
                        className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                        onClick={handleUpdate}
                    >
                        {t("send")}
                    </button>
                </div>
            </div>
        </div>
    );
}
