import { useEffect, useState } from "react";
import { cn, md5Encrypt } from "../../lib/utils";
import Logo from "../../assets/logo";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../loading-spinner";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import useModalsStore from "../../store/modalsStore";
import ForgotPassword from "./forgot-password";
import SelectCountryCode from "../modals/select-country-code";
import axios from "axios";

export default function OtpVerification() {
    const { isOtpModalOpen, setOtpModalOpen, isForgotPassword } =
        useModalsStore();
    const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
    const { t } = useTranslation();
    const [forgetToken, setForgetToken] = useState("");
    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);
    const [otpMethod, setOtpMethod] = useState<"sms" | "whatsapp">("sms");
    const [otpCode, setOtpCode] = useState("");

    const [mobile, setMobile] = useState("");
    const [countryCode, setCountryCode] = useState<string>("971");

    const [error, setError] = useState<1 | null>(null);
    const [loading, setLoading] = useState(false);

    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (step === 3) {
            const interval = setInterval(() => {
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                        setStep(2);
                    } else {
                        setMinutes((prev) => prev - 1);
                        setSeconds(59);
                    }
                } else {
                    setSeconds((prev) => prev - 1);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [minutes, seconds, step]);

    async function sendCode() {
        try {
            const formData = new FormData();
            formData.append("mobile", mobile);
            formData.append("country_code", countryCode);
            formData.append("channel", otpMethod);
            await axios.post("/user/forget_password/send_code", formData, {
                headers: {
                    "otp-token": md5Encrypt(mobile),
                },
            });

            setStep(3);
        } catch (e) {
            console.log(e);
        }
    }
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const handleOtpVerification = async () => {
        setError(null);
        setLoading(true);
        if (!mobile) {
            setError(1);
            setLoading(false);
            return;
        }
        const formData = new FormData();
        switch (step) {
            case 1:
                setStep(2);
                setLoading(false);
                break;
            case 2:
                sendCode();
                setLoading(false);
                break;
            case 3:
                try {
                    formData.append("code", otpCode);
                    formData.append("mobile", mobile);
                    const response = await axios.post(
                        "/user/forget_password/check_code",
                        formData,
                        {
                            headers: {
                                "otp-token": md5Encrypt(mobile),
                            },
                        },
                    );
                    const data = response.data.data;
                    console.log(data);

                    setForgetToken(data.forget_password_token);
                    toast.success(t("messages.otp-verified"));

                    if (!isForgotPassword) {
                        setStep(1);
                        setOtpModalOpen(false);

                        if (localStorage.getItem("firstVisit")) {
                            setMapModalOpen(true);
                        }
                    } else {
                        setStep(4);
                    }
                } catch (e) {
                    console.log(e);
                    toast.error(t("messages.incorrect-otp"));
                }
                setLoading(false);

                break;
            case 4:
                // setStep(1);
                // setOtpModalOpen(false);
                break;
        }
    };
    const handlePaste: React.ClipboardEventHandler = (event) => {
        const data = event.clipboardData.getData("text");
        console.log(data);
    };
    return (
        <>
            <div
                className={cn("flex flex-col", {
                    hidden: !isOtpModalOpen,
                })}
            >
                <div className="mb-4 flex">
                    <button
                        className={cn(
                            "transition hover:scale-125 rtl:rotate-180",
                            {
                                hidden: !isCountryCodeSelecting,
                            },
                        )}
                        onClick={() => {
                            setIsCountryCodeSelecting(false);
                        }}
                    >
                        <svg
                            width="40"
                            height="41"
                            viewBox="0 0 40 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_d_258_156948)">
                                <path
                                    d="M22.5 27.7148L15 19.5898L22.5 11.4648"
                                    stroke="#262626"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_258_156948"
                                    x="-5"
                                    y="0.214844"
                                    width="50"
                                    height="50"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                >
                                    <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset dy="5" />
                                    <feGaussianBlur stdDeviation="2.5" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_258_156948"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_258_156948"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                    </button>
                    <button
                        className="ms-auto transition hover:scale-125"
                        onClick={() => setOtpModalOpen(false)}
                    >
                        <svg
                            width="32"
                            height="33"
                            viewBox="0 0 32 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                fill="#262626"
                            />
                        </svg>
                    </button>
                </div>
                {!isCountryCodeSelecting && step === 1 && (
                    <>
                        <div className="mb-8 flex justify-center">
                            <Logo />
                        </div>
                        <span className="mb-4 block text-center text-2xl font-medium text-[rgba(15,23,42,1)]">
                            {t("modals.otp-verification")}
                        </span>
                        <span className="mb-8 block text-center text-lg text-[rgba(115,115,115,1)]">
                            {t("modals.otp-msg")}
                        </span>

                        <div className="mb-6 w-full">
                            <div className="flex items-center rounded-lg border px-4 py-[18px]">
                                <svg
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    className="me-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.4775 14.2475C16.4775 14.5175 16.4175 14.795 16.29 15.065C16.1625 15.335 15.9975 15.59 15.78 15.83C15.4125 16.235 15.0075 16.5275 14.55 16.715C14.1 16.9025 13.6125 17 13.0875 17C12.3225 17 11.505 16.82 10.6425 16.4525C9.78 16.085 8.9175 15.59 8.0625 14.9675C7.2 14.3375 6.3825 13.64 5.6025 12.8675C4.83 12.0875 4.1325 11.27 3.51 10.415C2.895 9.56 2.4 8.705 2.04 7.8575C1.68 7.0025 1.5 6.185 1.5 5.405C1.5 4.895 1.59 4.4075 1.77 3.9575C1.95 3.5 2.235 3.08 2.6325 2.705C3.1125 2.2325 3.6375 2 4.1925 2C4.4025 2 4.6125 2.045 4.8 2.135C4.995 2.225 5.1675 2.36 5.3025 2.555L7.0425 5.0075C7.1775 5.195 7.275 5.3675 7.3425 5.5325C7.41 5.69 7.4475 5.8475 7.4475 5.99C7.4475 6.17 7.395 6.35 7.29 6.5225C7.1925 6.695 7.05 6.875 6.87 7.055L6.3 7.6475C6.2175 7.73 6.18 7.8275 6.18 7.9475C6.18 8.0075 6.1875 8.06 6.2025 8.12C6.225 8.18 6.2475 8.225 6.2625 8.27C6.3975 8.5175 6.63 8.84 6.96 9.23C7.2975 9.62 7.6575 10.0175 8.0475 10.415C8.4525 10.8125 8.8425 11.18 9.24 11.5175C9.63 11.8475 9.9525 12.0725 10.2075 12.2075C10.245 12.2225 10.29 12.245 10.3425 12.2675C10.4025 12.29 10.4625 12.2975 10.53 12.2975C10.6575 12.2975 10.755 12.2525 10.8375 12.17L11.4075 11.6075C11.595 11.42 11.775 11.2775 11.9475 11.1875C12.12 11.0825 12.2925 11.03 12.48 11.03C12.6225 11.03 12.7725 11.06 12.9375 11.1275C13.1025 11.195 13.275 11.2925 13.4625 11.42L15.945 13.1825C16.14 13.3175 16.275 13.475 16.3575 13.6625C16.4325 13.85 16.4775 14.0375 16.4775 14.2475Z"
                                        stroke="#525252"
                                        strokeMiterlimit="10"
                                    />
                                </svg>

                                <input
                                    type="text"
                                    name="mobile"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    placeholder={t("modals.mobile")}
                                    className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                                />

                                <button
                                    className="flex items-center transition hover:opacity-80"
                                    onClick={() =>
                                        setIsCountryCodeSelecting(true)
                                    }
                                >
                                    +{countryCode}
                                    <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_258_154458)">
                                            <path
                                                d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                                                fill="#525252"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_258_154458">
                                                <rect
                                                    width="11.5"
                                                    height="11.5"
                                                    fill="white"
                                                    transform="translate(0.5 0.5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                            {error === 1 && (
                                <p className="font-light text-red-500">
                                    {t("modals.mobile")}{" "}
                                    {t("modals.errors.is-required")}
                                </p>
                            )}
                        </div>

                        <button
                            onClick={handleOtpVerification}
                            className="flex w-full items-center justify-center rounded-lg bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                        >
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                t("modals.send-btn")
                            )}
                        </button>
                    </>
                )}
                {!isCountryCodeSelecting && step === 2 && (
                    <>
                        <span className="mb-8 text-2xl font-medium text-foreground">
                            {t("modals.send-cod-via")}
                        </span>

                        <button
                            className={cn(
                                "border-[rgba(229,229,229,0.9) mb-4 rounded-xl border-[0.5px] py-4 transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        otpMethod === "sms",
                                },
                            )}
                            onClick={() => setOtpMethod("sms")}
                        >
                            {t("modals.sms")}
                        </button>
                        <button
                            className={cn(
                                "border-[rgba(229,229,229,0.9) mb-8 rounded-xl border-[0.5px] py-4 transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        otpMethod === "whatsapp",
                                },
                            )}
                            onClick={() => setOtpMethod("whatsapp")}
                        >
                            {t("modals.whatsapp")}
                        </button>

                        <button
                            onClick={handleOtpVerification}
                            className="flex w-full items-center justify-center rounded-lg bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                        >
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                t("modals.send-btn")
                            )}
                        </button>
                    </>
                )}
                {!isCountryCodeSelecting && step === 3 && (
                    <>
                        <div className="mb-8 flex justify-center">
                            <Logo />
                        </div>
                        <span className="mb-4 block text-center text-2xl font-medium text-[rgba(15,23,42,1)]">
                            {t("modals.otp-verification")}
                        </span>
                        <span className="mb-10 block text-center text-lg text-[rgba(115,115,115,1)]">
                            {t("modals.otp-verification-msg")}
                        </span>
                        <div
                            className="mb-8 flex justify-center text-black"
                            style={{ direction: "ltr" }}
                        >
                            <OtpInput
                                inputType="number"
                                inputStyle={{
                                    color: "rgba(15, 23, 42, 1)",
                                    textAlign: "center",
                                    border: "1px solid rgba(232, 236, 244, 1)",
                                    borderRadius: "8px",
                                    width: "80px",
                                    height: "80px",
                                    fontSize: "32px",
                                    paddingBottom: "8px",
                                    backgroundColor: "rgba(247, 248, 249, 1)",
                                }}
                                onPaste={handlePaste}
                                containerStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                    columnGap: "16px",
                                    width: "100%",
                                }}
                                value={otpCode}
                                onChange={setOtpCode}
                                numInputs={4}
                                renderInput={(props) => (
                                    <input
                                        {...props}
                                        className={cn("focus:outline-primary", {
                                            "!bg-white outline-primary":
                                                props.value !== "",
                                        })}
                                    />
                                )}
                                shouldAutoFocus
                                skipDefaultStyles
                            />
                        </div>

                        <button
                            onClick={handleOtpVerification}
                            className={cn(
                                "mb-8 flex w-full items-center justify-center rounded-lg bg-primary px-4 py-[18px] text-white transition hover:opacity-80",
                                {
                                    "bg-[rgba(115,115,115,1)]":
                                        otpCode.length < 4,
                                },
                            )}
                        >
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                t("modals.verify-btn")
                            )}
                        </button>

                        <div className="flex justify-center gap-x-2.5 text-[rgba(82,82,91,1)]">
                            <button
                                className={cn({
                                    "text-primary":
                                        minutes === 0 && seconds === 0,
                                })}
                                onClick={() => setStep(2)}
                            >
                                Resend OTP
                            </button>
                            <div className="tabular-nums">
                                0{minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </div>
                        </div>
                    </>
                )}
                {!isCountryCodeSelecting && step === 4 && (
                    <ForgotPassword
                        mobile={mobile}
                        forget_token={forgetToken}
                    />
                )}
                {isCountryCodeSelecting && (
                    <SelectCountryCode
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        setIsCountryCodeSelecting={setIsCountryCodeSelecting}
                    />
                )}
            </div>
        </>
    );
}
