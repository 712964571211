import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Emirate } from "../../../store/userStore";
import useSlaughterStore from "../../../store/slaughterStore";
import axios from "axios";
import NoDataIcon from "../../../assets/no-data-icon";
import { getEmirateId } from "../../../lib/middleware";

export type DeliveryPoint = {
    id: string;
    name: string;
    address: string;
    lat: number;
    lng: number;
    emirate_id: Emirate;
    region_id: number | null;
};

export default function ChooseDeiveryPoint() {
    const { t } = useTranslation();
    const [deliveryPoints, setDeliveryPoints] = useState<DeliveryPoint[]>([]);

    const setDeliveryPoint = useSlaughterStore(
        (state) => state.setDeliveryPoint,
    );
    const deliveryPoint = useSlaughterStore((state) => state.deliveryPoint);

    const [isChoosingDeliveryPoint, setIsChoosingDeliveryPoint] =
        useState(false);

    const addressId = useSlaughterStore((state) => state.address_id);

    async function fetchDeliveryPoints() {
        try {
            const formData = new FormData();
            formData.append("emirate_id", getEmirateId() || "8");
            const response = await axios.post("delivery_points", formData);
            setDeliveryPoints(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        if (addressId) {
            setDeliveryPoint(null);
        }
    }, [addressId]);

    useEffect(() => {
        fetchDeliveryPoints();
    }, []);

    return isChoosingDeliveryPoint ? (
        <>
            <div className="mb-10 flex flex-col gap-4">
                {deliveryPoints.length ? (
                    deliveryPoints.map((point) => (
                        <button
                            key={point.id}
                            className="flex flex-col rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
                            onClick={() => {
                                setIsChoosingDeliveryPoint(false);
                                setDeliveryPoint(point);
                            }}
                        >
                            <div className="flex w-full items-center gap-2">
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                        stroke="#292D32"
                                        strokeWidth="1.625"
                                    />
                                    <path
                                        d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                        stroke="#292D32"
                                        strokeWidth="1.625"
                                    />
                                </svg>
                                <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                                    {t("deliver-to")}
                                </span>
                            </div>
                            <span className="ms-[36px] text-[rgba(82,82,82,1)]">
                                {point.name} - {point.address}
                            </span>
                        </button>
                    ))
                ) : (
                    <div className="flex flex-col items-center justify-center gap-4">
                        <NoDataIcon />
                        <span className="text-lg text-[rgba(23,23,23,1)]">
                            {t("no-available-pickup")}
                        </span>
                    </div>
                )}
            </div>
        </>
    ) : deliveryPoint ? (
        <button
            key={deliveryPoint.id}
            className="flex flex-col rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
            onClick={() => setIsChoosingDeliveryPoint(true)}
        >
            <div className="flex w-full items-center gap-2">
                <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                        stroke="#292D32"
                        strokeWidth="1.625"
                    />
                    <path
                        d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                        stroke="#292D32"
                        strokeWidth="1.625"
                    />
                </svg>
                <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                    {t("deliver-to")}
                </span>
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-auto rtl:rotate-180"
                >
                    <path
                        d="M11.0367 21.7233L19.25 13.4867L11.0367 5.25"
                        stroke="#525252"
                        strokeWidth="2.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <span className="ms-[36px] text-[rgba(82,82,82,1)]">
                {deliveryPoint.name} - {deliveryPoint.address}
            </span>
        </button>
    ) : (
        <button
            onClick={() => setIsChoosingDeliveryPoint(true)}
            className="flex gap-2 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
        >
            <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-0.5"
            >
                <path
                    d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                    stroke="#292D32"
                    strokeWidth="1.625"
                />
                <path
                    d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                    stroke="#292D32"
                    strokeWidth="1.625"
                />
            </svg>
            <span className="text-xl font-medium text-[rgba(23,23,23,1)]">
                {t("choose-delivery-point")}
            </span>
        </button>
    );
}
