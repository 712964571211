import axios from "axios";
import { Category } from "../types/home-page";

export async function getCategory({ categoryId }: { categoryId: string }) {
    try {
        const response = await axios.get(`/categories/${categoryId}`);
        const data = response.data;
        return data.data as Category;
    } catch (error) {
        console.error(error);
    }
}
