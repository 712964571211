import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { CouponType } from "../components/cart/use-coupon";

export type CartType = "gifts" | "all" | "sacrifices" | "donations";

type CartStore = {
    points: string;
    balance: string;
    notes: string;
    coupon: CouponType | null;
    driverTip: string;
    paymentMethod: string;
    deliveryPrice: string;
    address_id: string;
    collection: Map<KeyType, ValueType>;
    setPoints: (points: string) => void;
    setBalance: (balance: string) => void;
    setNotes: (notes: string) => void;
    setCoupon: (coupon: CouponType | null) => void;
    setDriverTip: (driverTip: string) => void;
    setPaymentMethod: (paymentMethod: string) => void;
    addToCollection: (item: ValueType) => void;
    setDeliveryPrice: (deliveryPrice: string) => void;
    setAddressId: (address_id: string) => void;
};

const useCartStore = create<CartStore>()(
    devtools(
        (set) => ({
            points: "",
            balance: "",
            notes: "",
            coupon: null,
            paymentMethod: "",
            driverTip: "",
            collection: new Map(),
            deliveryPrice: "",
            address_id: "",
            setPoints: (points) => set({ points }),
            setBalance: (balance) => set({ balance }),
            setNotes: (notes) => set({ notes }),
            setCoupon: (coupon) => set({ coupon }),
            setDriverTip: (driverTip) => set({ driverTip }),
            setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
            addToCollection: (item) =>
                set((state) => {
                    const collection = new Map(state.collection);
                    collection.set(item.cart_id, item);
                    console.log("collection", collection);

                    return { collection };
                }),
            setDeliveryPrice: (deliveryPrice) => set({ deliveryPrice }),
            setAddressId: (address_id) => set({ address_id }),
        }),
        {
            enabled: true,
            name: "cart-store",
        },
    ),
);

export default useCartStore;

type KeyType = string;
type ValueType = CollectionItem;
export type CollectionItem = {
    cart_id: string;
    time_from: string;
    time_to: string;
    date: string;
    deliveryDay: string;
    shippingMethodId: string;
};
