import React from "react";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

export default function Search({
    setMarker,
    setMapCenter,
}: {
    setMarker: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral>>;
    setMapCenter: React.Dispatch<
        React.SetStateAction<google.maps.LatLngLiteral>
    >;
}) {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });

    const handleInput = (e: { target: { value: string } }) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect =
        ({ description }: { description: string }) =>
        () => {
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setValue(description, false);
            clearSuggestions();

            // Get latitude and longitude via utility functions
            getGeocode({ address: description })
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    setMarker({ lat, lng });
                    setMapCenter({ lat, lng });
                })
                .catch((error) => {
                    console.log("😱 Error: ", error);
                });
        };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                    className="cursor-pointer py-1 transition hover:bg-gray-50"
                >
                    <span className="text-lg font-medium">{main_text}</span>{" "}
                    {secondary_text}
                </li>
            );
        });

    return (
        <div className="relative flex flex-1 items-center gap-2 rounded-lg border border-[rgba(212,212,212,1)] px-2.5 py-2.5 xl:flex-1">
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="me-2"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.80524 16.2137C5.9404 15.3306 5.35381 14.2131 5.11824 12.9997C4.88072 11.7829 5.00269 10.5233 5.46924 9.37469C5.93181 8.23253 6.72153 7.25251 7.73924 6.55769C9.80409 5.14744 12.5224 5.14744 14.5872 6.55769C15.605 7.25251 16.3947 8.23253 16.8572 9.37469C17.3238 10.5233 17.4458 11.7829 17.2082 12.9997C16.9727 14.2131 16.3861 15.3306 15.5212 16.2137C14.3759 17.389 12.8044 18.0519 11.1632 18.0519C9.52213 18.0519 7.95059 17.389 6.80524 16.2137V16.2137Z"
                    stroke="#525252"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.1963 7.95177C10.9217 7.98087 10.7227 8.22707 10.7518 8.50167C10.7809 8.77628 11.0271 8.9753 11.3017 8.9462L11.1963 7.95177ZM13.2516 10.0949C13.3592 10.3492 13.6527 10.4681 13.9069 10.3604C14.1612 10.2528 14.2801 9.95936 14.1725 9.70506L13.2516 10.0949ZM15.9653 15.7641C15.7698 15.569 15.4532 15.5693 15.2582 15.7647C15.0631 15.9602 15.0633 16.2768 15.2588 16.4719L15.9653 15.7641ZM18.6468 19.8539C18.8422 20.0489 19.1588 20.0487 19.3539 19.8532C19.549 19.6578 19.5487 19.3412 19.3533 19.1461L18.6468 19.8539ZM11.3017 8.9462C12.1337 8.85805 12.9254 9.32447 13.2516 10.0949L14.1725 9.70506C13.6746 8.52913 12.4662 7.81721 11.1963 7.95177L11.3017 8.9462ZM15.2588 16.4719L18.6468 19.8539L19.3533 19.1461L15.9653 15.7641L15.2588 16.4719Z"
                    fill="#525252"
                />
            </svg>
            <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Enter an address"
                type="text"
                className="h-full w-full text-xs font-light focus:outline-none"
            />
            {status === "OK" && (
                <ul className="absolute left-0 top-[110%] z-[9999] w-full rounded-lg bg-white p-2">
                    {renderSuggestions()}
                </ul>
            )}
        </div>
    );
}
