import { useTranslation } from "react-i18next";
import NavMenu from "./nav-menu";
import { Link, useNavigate } from "react-router-dom";
import useModalsStore from "../../store/modalsStore";
import { getToken } from "../../lib/middleware";

export default function NavList() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const setLoginModalOpen = useModalsStore(
        (state) => state.setLoginModalOpen,
    );

    const checkLogin = () => {
        if (!getToken()) {
            setLoginModalOpen(true);
            return false;
        }
        return true;
    };

    return (
        <div className="flex gap-x-7 gap-y-3 max-xl:flex-col xl:items-center">
            <Link
                to="/"
                className="flex items-center gap-x-0.5 text-lg text-[rgba(38,38,38,1)] transition hover:opacity-80"
            >
                {t("navlist.home")}
                <svg
                    width="48"
                    height="41"
                    viewBox="0 0 48 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21.02 11.3402L15.63 15.5402C14.73 16.2402 14 17.7302 14 18.8602V26.2702C14 28.5902 15.89 30.4902 18.21 30.4902H29.79C32.11 30.4902 34 28.5902 34 26.2802V19.0002C34 17.7902 33.19 16.2402 32.2 15.5502L26.02 11.2202C24.62 10.2402 22.37 10.2902 21.02 11.3402Z"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M24 26.4902V23.4902"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Link>
            <button
                onClick={() => {
                    if (checkLogin()) navigate("/account");
                }}
                className="flex items-center gap-x-0.5 text-lg text-[rgba(38,38,38,1)] transition hover:opacity-80"
            >
                {t("navlist.account")}
                <svg
                    width="48"
                    height="41"
                    viewBox="0 0 48 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M24.1601 19.37C24.0601 19.36 23.9401 19.36 23.8301 19.37C21.4501 19.29 19.5601 17.34 19.5601 14.94C19.5601 12.49 21.5401 10.5 24.0001 10.5C26.4501 10.5 28.4401 12.49 28.4401 14.94C28.4301 17.34 26.5401 19.29 24.1601 19.37Z"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19.1602 23.06C16.7402 24.68 16.7402 27.32 19.1602 28.93C21.9102 30.77 26.4202 30.77 29.1702 28.93C31.5902 27.31 31.5902 24.67 29.1702 23.06C26.4302 21.23 21.9202 21.23 19.1602 23.06Z"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <button
                onClick={() => {
                    if (checkLogin()) navigate("/cart");
                }}
                className="flex items-center gap-x-0.5 text-lg text-[rgba(38,38,38,1)] transition hover:opacity-80"
            >
                {t("navlist.cart")}
                <svg
                    width="48"
                    height="41"
                    viewBox="0 0 48 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_258_178322)">
                        <path
                            d="M15 10.75C14.5858 10.75 14.25 11.0858 14.25 11.5C14.25 11.9142 14.5858 12.25 15 12.25V10.75ZM17 11.5L17.7478 11.4425C17.7177 11.0517 17.3919 10.75 17 10.75V11.5ZM33 14.5L33.7454 14.5828C33.769 14.3708 33.7011 14.1588 33.5588 13.9998C33.4166 13.8408 33.2133 13.75 33 13.75V14.5ZM17.2308 14.5L16.483 14.5575L17.2308 14.5ZM30.3387 23.6187L30.3921 24.3668L30.3387 23.6187ZM19.984 24.3583L19.9306 23.6102L19.984 24.3583ZM15 12.25H17V10.75H15V12.25ZM20.0375 25.1064L30.3921 24.3668L30.2852 22.8706L19.9306 23.6102L20.0375 25.1064ZM32.9293 21.9274L33.7454 14.5828L32.2546 14.4172L31.4385 21.7618L32.9293 21.9274ZM16.2522 11.5575L16.483 14.5575L17.9786 14.4425L17.7478 11.4425L16.2522 11.5575ZM16.483 14.5575L17.0997 22.5743L18.5952 22.4592L17.9786 14.4425L16.483 14.5575ZM33 13.75H17.2308V15.25H33V13.75ZM30.3921 24.3668C31.7156 24.2722 32.7828 23.2462 32.9293 21.9274L31.4385 21.7618C31.3719 22.3612 30.8868 22.8276 30.2852 22.8706L30.3921 24.3668ZM19.9306 23.6102C19.2447 23.6592 18.648 23.1449 18.5952 22.4592L17.0997 22.5743C17.2157 24.0827 18.5284 25.2142 20.0375 25.1064L19.9306 23.6102Z"
                            fill="#262626"
                        />
                        <rect
                            x="20.5"
                            y="29"
                            width="0.01"
                            height="0.01"
                            stroke="#262626"
                            strokeWidth="2.25"
                            strokeLinejoin="round"
                        />
                        <rect
                            x="29.5"
                            y="29"
                            width="0.01"
                            height="0.01"
                            stroke="#262626"
                            strokeWidth="2.25"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_258_178322">
                            <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(12 8.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </button>
            <button
                onClick={() => {
                    if (checkLogin()) navigate("/notifications");
                }}
                className="flex items-center gap-x-0.5 text-lg text-[rgba(38,38,38,1)] transition hover:opacity-80"
            >
                {t("navlist.notifications")}
                <svg
                    width="48"
                    height="41"
                    viewBox="0 0 48 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M24 14.9395V18.2695"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M24.0199 10.5C20.3399 10.5 17.3599 13.48 17.3599 17.16V19.26C17.3599 19.94 17.0799 20.96 16.7299 21.54L15.4599 23.66C14.6799 24.97 15.2199 26.43 16.6599 26.91C21.4399 28.5 26.6099 28.5 31.3899 26.91C32.7399 26.46 33.3199 24.88 32.5899 23.66L31.3199 21.54C30.9699 20.96 30.6899 19.93 30.6899 19.26V17.16C30.6799 13.5 27.6799 10.5 24.0199 10.5Z"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M27.3299 27.3203C27.3299 29.1503 25.8299 30.6503 23.9999 30.6503C23.0899 30.6503 22.2499 30.2703 21.6499 29.6703C21.0499 29.0703 20.6699 28.2303 20.6699 27.3203"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                </svg>
            </button>

            <NavMenu />
        </div>
    );
}
