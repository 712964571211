import { eachDayOfInterval, endOfDay, format, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../loading-spinner";
import { cn } from "../../../lib/utils";
import axios from "axios";
import { DriverSvg } from "../../../assets/DriverSvg";
import useCartStore, {
    CartType,
    CollectionItem,
} from "../../../store/cartStore";
import useGiftsStore from "../../../store/giftsStore";

export type DeliveryTime = { available: boolean; from: string; to: string };
export type ShippingMethod = { id: number; name: string; price: number };

export default function ChooseDeliverySlot({
    cart_id,
    className,
    type = "all",
}: {
    cart_id: string;
    className?: string;
    type?: CartType;
}) {
    const { t } = useTranslation();
    const [isChoosingDelivery, setIsChoosingDelivery] = useState(false);
    const [dateFrom, setDateFrom] = useState<Date | null>();
    const [dateTo, setDateTo] = useState<Date | null>();
    const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(
        [],
    );
    const [deliveryTimes, setDeliveryTimes] = useState<DeliveryTime[]>([]);
    const [notAvailableDates, setNotAvailableDates] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [shippingMethod, setShippingMethod] = useState<ShippingMethod | null>(
        null,
    );
    const [confirmSlot, setConfirmSlot] = useState(false);
    const [deliveryDay, setDeliveryDay] = useState<string | null>(null);
    const [deliveryTime, setDeliveryTime] = useState<DeliveryTime | null>(null);

    const fetchDeliverySlots = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("cart_id", cart_id);
            shippingMethod &&
                formData.append(
                    "shipping_method_id",
                    shippingMethod.id.toString(),
                );
            if (deliveryDay) formData.append("date", deliveryDay);
            const response = await axios.post("/checkout/settings", formData);
            const _data = response.data.data;
            setDateFrom(new Date(_data.date_from));
            setDateTo(new Date(_data.date_to));
            setShippingMethods(_data.shipping_methods);
            setNotAvailableDates(_data.not_available_dates);
            !shippingMethod && setShippingMethod(_data.shipping_methods[0]);
            setDeliveryTimes(_data.delivery_times);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDeliverySlots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addToCollection = useCartStore((state) => state.addToCollection);
    const setDeliveryPrice = useCartStore((state) => state.setDeliveryPrice);
    const deliveryPrice = useCartStore((state) => state.deliveryPrice);
    const setDeliveryDate = useGiftsStore((state) => state.setDeliveryDate);

    const addItemsToCollection = () => {
        if (type === "all") {
            const newItem: CollectionItem = {
                cart_id,
                time_from: deliveryTime?.from!,
                time_to: deliveryTime?.to!,
                date: deliveryDay!,
                deliveryDay: format(new Date(deliveryDay!), "EEEE"),
                shippingMethodId: shippingMethod!.id.toString(),
            };
            addToCollection(newItem);
            setDeliveryPrice(deliveryPrice + shippingMethod!.price);
        } else if (type === "gifts") {
            setDeliveryDate({
                time_from: deliveryTime?.from!,
                time_to: deliveryTime?.to!,
                date: deliveryDay!,
                deliveryDay: format(new Date(deliveryDay!), "EEEE"),
            });
        }
    };

    return (
        <>
            <button
                onClick={() => {
                    setIsChoosingDelivery(true);
                }}
                className={cn(
                    "flex items-start gap-4 rounded-lg border border-[rgba(245,245,245,1)] bg-[rgba(250,250,250,1)] p-4 transition hover:opacity-80",
                    {
                        "items-center": !deliveryTime,
                    },
                    className,
                )}
            >
                <DriverSvg className="w-[88px]" />
                {confirmSlot ? (
                    <div className="flex flex-col items-start gap-2">
                        <span className="text-lg capitalize text-[rgba(214,60,70,1)]">
                            {shippingMethod?.name} {shippingMethod?.price}{" "}
                            {t("AED")}
                        </span>
                        <span className="text-[rgba(82,82,82,1)]">
                            {deliveryDay && deliveryDay}
                        </span>
                        <span className="text-[rgba(82,82,82,1)]" dir="ltr">
                            {deliveryTime?.to} - {deliveryTime?.from}
                        </span>
                    </div>
                ) : (
                    <div className="flex flex-col items-start gap-2 leading-none">
                        <span className="text-lg font-medium text-[rgba(38,38,38,1)]">
                            {t("select-delivery")}
                        </span>
                        <span className="text-[rgba(82,82,82,1)]">
                            {t("date-time")}
                        </span>
                    </div>
                )}
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-auto rtl:rotate-180"
                >
                    <path
                        d="M11.0367 21.7233L19.25 13.4867L11.0367 5.25"
                        stroke="#525252"
                        strokeWidth="2.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>

            {isChoosingDelivery && (
                <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed">
                    <div className="ReactModal__Content ReactModal__Content--after-open !max-w-[1076px] bg-white">
                        <div className="flex flex-col gap-10 sm:-m-4">
                            <div className="flex items-center justify-between">
                                <span className="text-2xl font-medium text-[rgba(15,23,42,1)]">
                                    {t("delivery-selection")}
                                </span>
                                <button
                                    className="transition hover:scale-110"
                                    onClick={() => setIsChoosingDelivery(false)}
                                >
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.08366 8.0804C8.7085 7.45557 9.72156 7.45557 10.3464 8.0804L16.0033 13.7373L21.6601 8.0804C22.2849 7.45556 23.298 7.45557 23.9228 8.0804C24.5477 8.70524 24.5477 9.71831 23.9229 10.3431L18.266 16L23.9229 21.6569C24.5477 22.2817 24.5477 23.2948 23.9228 23.9196C23.298 24.5444 22.2849 24.5444 21.6601 23.9196L16.0033 18.2627L10.3464 23.9196C9.72156 24.5444 8.7085 24.5444 8.08366 23.9196C7.45882 23.2948 7.45882 22.2817 8.08366 21.6569L13.7405 16L8.08366 10.3431C7.45882 9.71831 7.45882 8.70524 8.08366 8.0804Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 rounded-lg border border-[rgba(229,229,229,1)] p-4">
                                {shippingMethods.map((_method, idx) => (
                                    <button
                                        key={_method.id}
                                        className="flex items-center justify-between gap-4 capitalize"
                                        onClick={() =>
                                            setShippingMethod(_method)
                                        }
                                    >
                                        <div className="flex items-center gap-2">
                                            <svg
                                                width="26"
                                                height="26"
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="13"
                                                    cy="12.9993"
                                                    r="7.75568"
                                                    stroke="#0A4C26"
                                                    strokeWidth="1.03409"
                                                />
                                                {shippingMethod?.id ===
                                                    _method.id && (
                                                    <circle
                                                        cx="13.0001"
                                                        cy="12.9995"
                                                        r="6.20455"
                                                        fill="#0A4C26"
                                                    />
                                                )}
                                            </svg>
                                            <span className="text-[rgba(38,38,38,1)]">
                                                {_method.name}
                                            </span>
                                        </div>
                                        <span className="text-lg text-[rgba(38,38,38,1)]">
                                            +{_method.price} {t("AED")}
                                        </span>
                                    </button>
                                ))}
                            </div>
                            <div className="flex flex-wrap justify-center gap-4">
                                {getDatesBetween(dateFrom!, dateTo!).map(
                                    (_date, idx) => (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                setDeliveryDay(
                                                    format(_date, "yyyy-MM-dd"),
                                                );
                                                setDeliveryTime(null);
                                                setDeliveryTimes([]);
                                                fetchDeliverySlots();
                                            }}
                                            disabled={notAvailableDates?.includes(
                                                format(_date, "yyyy-MM-dd"),
                                            )}
                                            className={cn(
                                                "flex flex-col items-center gap-3 rounded-lg border border-[rgba(229,229,229,1)] px-3.5 py-3",
                                                {
                                                    "border-primary":
                                                        deliveryDay ===
                                                        format(
                                                            _date,
                                                            "yyyy-MM-dd",
                                                        ),
                                                    "opacity-50":
                                                        notAvailableDates?.includes(
                                                            format(
                                                                _date,
                                                                "yyyy-MM-dd",
                                                            ),
                                                        ),
                                                },
                                            )}
                                        >
                                            <span className="w-28 font-medium text-[rgba(38,38,38,1)]">
                                                {t(format(_date, "EEEE"))}
                                            </span>
                                            <span className="text-sm font-light text-[rgba(64,64,64,1)]">
                                                {format(_date, "dd-MM-yyyy")}
                                            </span>
                                        </button>
                                    ),
                                )}
                            </div>

                            {deliveryDay && (
                                <div className="flex flex-wrap justify-center gap-4">
                                    {deliveryTimes.map((_time, idx) => (
                                        <button
                                            dir="ltr"
                                            key={idx}
                                            onClick={() =>
                                                setDeliveryTime(_time)
                                            }
                                            disabled={!_time.available}
                                            className={cn(
                                                "flex flex-col items-center gap-3 rounded-lg border border-[rgba(229,229,229,1)] px-3.5 py-3",
                                                {
                                                    "border-primary":
                                                        _time === deliveryTime,
                                                    "opacity-50":
                                                        !_time.available,
                                                },
                                            )}
                                        >
                                            <span className="font-medium capitalize text-[rgba(38,38,38,1)]">
                                                {_time.available
                                                    ? t("available")
                                                    : t("not-available")}
                                            </span>
                                            <span className="w-[220px] text-sm font-light text-[rgba(64,64,64,1)]">
                                                {_time.from} - {_time.to}
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            )}
                            {deliveryTime && (
                                <div className="flex justify-center">
                                    <button
                                        onClick={() => {
                                            // fetchDeliverySlots();
                                            setIsChoosingDelivery(false);
                                            setConfirmSlot(true);
                                            addItemsToCollection();
                                        }}
                                        className="flex w-full max-w-[302px] items-center justify-center rounded-lg bg-primary px-6 py-[18px] text-white"
                                    >
                                        {loading ? (
                                            <LoadingSpinner />
                                        ) : (
                                            t("confirm-slot")
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function getDatesBetween(startDate: Date, endDate: Date) {
    const start = startOfDay(startDate);
    const end = endOfDay(endDate);
    const dates = [];

    for (const date of eachDayOfInterval({ start, end })) {
        dates.push(date);
    }

    return dates;
}
